@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap);
#login-container {
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    align-items: stretch;
    overflow: hidden;
}

#login-container .left-side {
    height: inherit;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    position: relative;
}

#login-container .left-side .left-side-cover {
    position: absolute;
    right: -25%;
    bottom: -68px;
    width: 100%;
    height: auto;
}

#login-container .left-side .left-side-wrapper {
    height: 100%;
    height: calc(100% - 64px);
    background-size: contain;
    width: 100%;
    padding: 32px;
}

#login-container .left-side .left-side-wrapper h1 {
    font-size: 6vw;
    letter-spacing: 0;
    margin-top: 55px;
    width: 80%;
    width: calc(80% - 36px);
    color: #fff;
}

#login-container .right-side {
    height: inherit;
    padding-left: 146px;
    padding-right: 42px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #fff;
    z-index: 1;
    position: relative;
}

#login-container .right-side-wrapper {

}


#login-container .right-side .back-action {
    font-size: 16px;
    position: absolute;
    right: 0;
    top: 0;
}

#login-container .right-side .back-action button {
    text-transform: unset;
    font-size: 16px;
}

#login-container .right-side .title {
    flex: 1 1 100%;
    max-width: 100%;
    text-align: left;
    margin-bottom: 30px;
}

#login-container .right-side .title strong {
    font-size: 35px;
    display: block;
    margin-bottom: 20px;
}

#login-container .right-side .title span {
    color: #03014c;
    font-size: 14px;
    letter-spacing: 0;
    opacity: 0.5;
    line-height: 28px;
}

#login-container .right-side form .account-type {
    margin-bottom: 20px;
}

#login-container .right-side form .email-field {
    margin-bottom: 20px;
}

#login-container .right-side form .password-field {
    margin-bottom: 10px;
}

#login-container .right-side form .email-field input,
#login-container .right-side form .password-field input {
    height: 76px;
    font-size: 16px;
    padding: 0;
}


#login-container .right-side form .email-field .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel),
#login-container .right-side form .password-field .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
}

#login-container .right-side .login-button {
    height: 76px;
    font-size: 20px;
    text-transform: unset;
    margin-top: 75px;
}

@media screen and (max-width: 1024px) {
    #login-container {
        height: unset;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    #login-container .left-side {
        display: none!important;
    }

    #login-container .right-side {
        flex: 1 1 100%;
        max-width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        background-color: #fff;
    }

    #login-container .right-side .right-side-wrapper {
        padding-top: 70px;
        padding-bottom: 268px;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: auto 268px;
        height: 100%;
    }

    #login-container .right-side .right-side-header {
        text-align: center;
        margin-bottom: 54px;
    }

    #login-container .right-side .right-side-header h5 {
        margin-top: 44px;
        font-size: 48px;
        letter-spacing: 0;
        font-weight: bold;
        margin-bottom: 0;
    }

    #login-container .right-side .title strong {
       font-size: 28px;
       margin-bottom: 20px;
    }


    #login-container .right-side .title span {
        opacity: 1;
    }

    #login-container .right-side .login-button {
        height: 50px;
        font-size: 16px;
    }
}

@media screen and (max-width: 576px) {
    #login-container .right-side .right-side-header h5 {
        font-size: 28px;
    }

    #login-container .right-side .title strong {
        font-size: 22px;
    }
}

#otp-form {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

#otp-form input {
  width: 58px;
  height: 58px;
  border-radius: 8px;
  border: solid 1px #cfcfcf;
  background-color: #f1f1f1;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #000;
}

#otp-form input:not(:last-child) {
  margin-right: 27px;
}
.profile-summary-view-wrapper.__narrow {
  text-align: center;
}

.profile-summary-view-wrapper.__narrow .profile-picture-area{
  border-radius: 50%;
  width: 62px;
  height: 62px;
  margin: 0 auto;
  margin-bottom: 10px;
  overflow: hidden;
}

.profile-summary-view-wrapper.__wide .profile-picture-area{
  border-radius: 50%;
  width: 112px;
  height: 112px;
  margin: 0 auto;
  margin-bottom: 14px;
  overflow: hidden;
}

.profile-summary-view-wrapper .profile-picture-area .image-wrapper{
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}

.profile-summary-view-wrapper .profile-picture-area img{
  max-width: 100%;
  height: auto;
}

/* @LEFT SIDE - Titles */
.profile-summary-view-wrapper.__wide .profile-title .fullname {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: bold;
  color: #3f526d;
}
.profile-summary-view-wrapper.__narrow .profile-title .fullname {
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: #3f526d;
}

.profile-summary-view-wrapper .profile-title .title-line {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-summary-view-wrapper.__wide .profile-title .title-line .title{
  color: #3f526d;
  font-size: 16px;
  opacity: 0.68;
}

.profile-summary-view-wrapper.__narrow .profile-title .title-line .title{
  font-size: 16px;
  color: rgba(63, 82, 109, 0.5);
  font-weight: 600;
  line-height: 16px;
}

.profile-summary-view-wrapper .profile-title .verified-user-icon {
  color: #2d97ff;
  width: 12px;
  height: 12px;
  margin-left: 4px;
}


.profile-summary-view-wrapper.__wide .user-summary-count-wrapper{ margin-top: 40px; }
.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper{ margin-top: 10px; }

.profile-summary-view-wrapper .user-summary-count-wrapper .summary-item {
  display: flex;
  align-items: center;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count {
  margin-left: 21px;
  margin-right: 4px;
  font-size: 18px;
  color: #3f526d;
  font-weight: bold;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .item-name {
  font-size: 18px;
  color: rgba(63, 82, 109, 0.5);
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item .count {
  margin-left: 10px;
  color: rgba(63, 82, 109, 0.5);
  font-size: 16px;
  font-weight: 600;
}

.profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item {
  margin-bottom: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 64px;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper {
  display: inline-block;
  text-align: center;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item{
  box-shadow: none;
  margin: 0;
  padding: 6px 12px;
  display: inline-block;
  float: left;
  background-color: unset;
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item:nth-child(3){
  clear: left; 
}

.profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item > *{
  vertical-align: middle;
}

@media screen and (max-width: 1024px) {
  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item img {
    width: 24px;
    height: 24px;
  }

  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item .count {
    font-size: 14px;
    margin-left: 8px;
  }

  .profile-summary-view-wrapper.__narrow .profile-title .fullname {
    font-weight: 500;
  }

  .profile-summary-view-wrapper.__narrow .profile-title .title-line .title {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper {
    display: flex;
    align-items:  center;
  }

  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 6px 6px;
  }

  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item:first-child{
    padding-left: 0;
  }

  .profile-summary-view-wrapper.__narrow .user-summary-count-wrapper .summary-item:last-child{
    padding-right: 0;
  }
}

#sidebar {
    padding: 26px 0px;
    text-align: center;
    position: relative;
    z-index: 3;
    transition: 100ms linear;
    -webkit-transition: 100ms linear;
    -moz-transition: 100ms linear;
    -ms-transition: 100ms linear;
    -o-transition: 100ms linear;
}

#sidebar .sidebar-collapser {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 115px;
    right: -15px;
    background-color: #ffbe00;
    max-width: 30px;
    min-width: unset;
    z-index: 1;
}

#sidebar .sidebar-collapser .chevron-icon {
    transition: 300ms ease transform;
    transition-delay: 300ms;
}

#sidebar .nav-menu-wrapper {
    text-align: center;
    margin-top: 40px;
}

#sidebar .nav-menu-wrapper .nav-menu-item {
    opacity: 0.5;
    display: block;
    min-width: unset;
    text-transform: unset;
    padding-left: 45px;
    padding-right: 15px;
    width: 100%;
    margin: 0;
    min-height: 40px;
    height: 80px;
    height: calc(100vh / 11.5);
    max-height: 90px;
}

#sidebar .nav-menu-wrapper .nav-menu-item:hover {
    opacity: 1;
}

#sidebar .nav-menu-wrapper .nav-menu-item .button-text {
    opacity: 1;
    transform: translateX(0%);
    transition: 300ms ease;
}

#sidebar .nav-menu-wrapper .nav-menu-item .MuiButton-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#sidebar .nav-menu-wrapper .nav-menu-item .MuiButton-label svg:nth-of-type(1) {
    flex: 0 0 28px;
    max-width: 28px;
    width: 28px;
}

#sidebar .nav-menu-wrapper .nav-menu-item .button-text {
    margin-left: 16px;
    color: #fff;
    font-size: 16px;
    pointer-events: none;
}

/* Responsive Designs */
@media screen and (min-width: 1025px) {  
    #sidebar.__collapsed {
        flex: 0 0 120px;
        max-width: 120px;
        width: 120px;
    }
    
    #sidebar:not(.__collapsed) {
        flex: 0 0 265px;
        max-width: 265px;
        width: 265px;
    }
  
    #sidebar:not(.__collapsed) .sidebar-collapser .chevron-icon {
        transform: rotate(180deg);
    }

    #sidebar.__collapsed .nav-menu-wrapper .nav-menu-item .button-text {
        opacity: 0;
        transform: translateX(150%);
    }


    #sidebar:not(.__collapsed) .nav-menu-wrapper .nav-menu-item {
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    #sidebar {
        position: fixed;
        left: 0;
        top: 64px;
        background-color: #fff;
        background-image: unset!important;
        box-shadow: 5px 20px 10px 0 rgba(0, 0, 0, 0.16);
        overflow: hidden;
        text-align: left;
        padding: 20px 0px 20px 28px;
        flex: 0 0 280px;
        max-width: 280px;
        width: 280px;
        transform: translateX(-300px);
        -webkit-transform: translateX(-300px);
        -moz-transform: translateX(-300px);
        -ms-transform: translateX(-300px);
        -o-transform: translateX(-300px);
        z-index: 999; /*137=64+40(padding)*/
        height: calc(100% - 64px);
        box-sizing: border-box;
    }

    #sidebar .logo-area {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        padding-right: 10px;
    }

    #sidebar .nav-menu-wrapper {
        height: calc(100% - 180px);
        height: calc(calc(1vh * 100) - 290px);
        height: calc(calc(var(--vh, 1vh) * 100) - 290px);
        overflow-y: auto;
        width: 100%;
        width: calc(100% + 16px);
    }

    #sidebar .nav-menu-wrapper .nav-menu-item {
        opacity: 1;
        padding-top: 22px;
        padding-bottom: 22px;
        height: unset;
        width: calc(100% - 34px)!important;
        padding-right: 0;
        padding-left: 0;
        border-bottom: solid 1px rgba(63, 82, 109, 0.12);
    }

    #sidebar .nav-menu-wrapper .nav-menu-item .button-text {
        color: #3f526d;
        display: inline-block;
        width: 100%;
        text-align: left;
    }

    #sidebar .nav-menu-wrapper .nav-menu-item .item-right-button {
        font-size: 28px;
        display: block;
        transition: 100ms linear;
        -webkit-transition: 100ms linear;
        -moz-transition: 100ms linear;
        -ms-transition: 100ms linear;
        -o-transition: 100ms linear;
}

    #sidebar .nav-menu-wrapper .nav-menu-item:hover .item-right-button {
        transform: translateX(5px);
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        -ms-transform: translateX(5px);
        -o-transform: translateX(5px);
    }

    #sidebar .logout-button {
        border-bottom: solid 1px rgba(63, 82, 109, 0.12);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 22px;
        padding-bottom: 22px;
        flex-wrap: nowrap;
        color: #3f526d;
        text-transform: unset;
        font-size: 16px;
        padding-left: 0;
        padding-right: 0;
        width: calc(100% - 18px)!important;
        margin-top: 20px;
    }
    
    #sidebar .logout-button .MuiButton-label svg:nth-of-type(1) {
        flex: 0 0 28px;
        max-width: 28px;
        width: 28px;
        margin-right: 16px;
    }    
}

header#app-header {
    width: 100%;
}

header#app-header:not(.__has-page-header) {
    margin-bottom: 28px;
}

header#app-header.__has-page-header {
    margin-bottom: 14px;
}

header#app-header .page-header-wrapper {
    padding: 16px 0;
}

header#app-header #page-header .page-header-button-text {
    font-size: 20px;
}

header#app-header .header-content {
    height: 88px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgba(63, 82, 109, 0.28);
}

header#app-header .header-content .header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

header#app-header .header-content .action-icons {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-right: 64px;
    position: relative;
    height: 48px;
}

header#app-header .header-content .action-icons img {
    -o-object-fit: contain;
       object-fit: contain;
}

header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(6) { transition-delay: 100; transform: translateX(0); -webkit-transform: translateX(0); -moz-transform: translateX(0); -ms-transform: translateX(0); -o-transform: translateX(0); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(5) { transition-delay: 200; transform: translateX(-48px); -webkit-transform: translateX(-48px); -moz-transform: translateX(-48px); -ms-transform: translateX(-48px); -o-transform: translateX(-48px); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(4) { transition-delay: 300; transform: translateX(-96px); -webkit-transform: translateX(-96px); -moz-transform: translateX(-96px); -ms-transform: translateX(-96px); -o-transform: translateX(-96px); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(3) { transition-delay: 400; transform: translateX(-144px); -webkit-transform: translateX(-144px); -moz-transform: translateX(-144px); -ms-transform: translateX(-144px); -o-transform: translateX(-144px); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(2) { transition-delay: 500; transform: translateX(-192px); -webkit-transform: translateX(-192px); -moz-transform: translateX(-192px); -ms-transform: translateX(-192px); -o-transform: translateX(-192px); }
header#app-header .header-content .action-icons.__show .action-item-wrapper:nth-child(1) { transition-delay: 600; transform: translateX(-240px); -webkit-transform: translateX(-240px); -moz-transform: translateX(-240px); -ms-transform: translateX(-240px); -o-transform: translateX(-240px); }

header#app-header .header-content .action-icons .action-item-wrapper:nth-child(6) { transition-delay: 600; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(5) { transition-delay: 500; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(4) { transition-delay: 400; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(3) { transition-delay: 300; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(2) { transition-delay: 200; }
header#app-header .header-content .action-icons .action-item-wrapper:nth-child(1) { transition-delay: 100; }


header#app-header .header-content .action-icons .action-item-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    transform: translateX(0);
    pointer-events: none;
    transition: 300ms ease;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
}

header#app-header .header-content .action-icons.__show .action-item-wrapper {
    opacity: 1;
    pointer-events: unset;
    -webkit-user-select: unset;
       -moz-user-select: unset;
            user-select: unset;
}


header#app-header .header-content .action-icons .action-item-wrapper.notifications-action svg {
    color: #ffc009;
    font-size: 28px;
}

header#app-header .header-content .action-icons .action-item-wrapper.notifications-action .MuiBadge-anchorOriginTopRightRectangle {
    top: 7px;
    right: 4px;
    height: 14px;
    min-width: 14px;
}

header#app-header .header-content .profile-button {
    width: 28px;
    height: 28px;
    min-width: 28px;
    max-width: 28px;
    border-radius: 50%;
    margin-right: 18px;
    padding-left: 14px;
    padding-right: 14px;
}

header#app-header .header-content .profile-picture {
    width: 64px;
    height: 64px;
}

/* Responsive Designs */
@media screen and (min-width: 1025px) {
    header#app-header .header-content{
        background-color: unset!important;
    }
}

@media screen and (max-width: 1024px) {
    header#app-header {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 999;
    }

    header#app-header.__has-page-header .header-content {
        border-width: 0;
    }

    header#app-header .header-content {
        height: 64px;
        width: 100%;
        padding: 0 28px 0 28px;
        box-sizing: border-box;
    }

    header#app-header .header-content .header-actions .profile-button {
        background-color: #fff;
        background-image: unset!important;
    }

    header#app-header .header-content .profile-picture {
        width: 34px;
        height: 34px;
    }


    header#app-header.__has-page-header .page-header-wrapper {
        border-bottom: solid 1px rgba(63, 82, 109, 0.28);
    }

    header#app-header .page-header-wrapper {
        background-color: #fff;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 28px;
        padding-right: 28px;
        height: 58px;
    }

    header#app-header .page-header-wrapper #page-header {
        height: 100%;
    }
}

@media screen and (max-width: 767px) {
    header#app-header .header-content {
        padding: 0 16px;
    }

    header#app-header .page-header-wrapper {
        padding: 0 16px;
    }

    header#app-header .header-content .action-icons {
        margin-right: 0;
    }
}
.notification-menu-list .MuiPopover-paper{
    border-radius: 6px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    width:480px;
    max-height:640px;
}

.notification-menu-list-header{
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:20px 30px;
}

.notification-menu-list-header-separator{
    border: solid 1px rgba(63, 82, 109, 0.28);
    margin:0;
    border-bottom-width:0;
}

.notification-menu-list-header h3{
    font-size:14px;
    font-weight:500;
    margin:0;
}

.notification-menu-list-header img{
    width:18px;
    height:18px;
}

.notification-menu-list-body{
    padding:9px 30px 0 30px;
}

.notification-menu-list-item{
    display:flex;
    align-items:center;
}

.notification-menu-list-item-info span{
    font-size:14px;
    margin-bottom:2px;
    color: #3f526d;
}

.notification-menu-list-item-date{
    font-size:12px;
}

.notification-menu-list-username{
    font-weight:500;
}

.notification-menu-list-item img{
    margin-right:14px;
    width:38px;
    height:38px;
    border-radius:50%;
}

.notification-menu-list-body hr{
    border: solid 0.5px rgba(63, 82, 109, 0.28);
    margin:13px 0;
    border-bottom-width:0;
}

.notification-menu-list .view-all-button{
    height:38px;
    width:160px;
    margin:0 auto;
    text-transform:capitalize!important;
    font-size:12px!important;
    font-weight:500!important;
}

.notification-menu-list-date{
    font-size:12px;
    font-weight:500;
    margin-bottom:18px;
}

.notification-view-all-button{
    width:100%;
    display:flex;
    justify-content:center;
}

@media(max-width:768px){
    .notification-menu-list-header{
        padding:20px;
    }
    .notification-menu-list-header h3{
        font-size:12px;
    }
    .notification-menu-list-date{
        margin-bottom:13px;
    }
    .notification-menu-list-body{
        padding:15px 20px 0 20px;
    }
    .notification-menu-list-item img{
        width:28px;
        height:28px;
        margin-right:6px;
    }
    .notification-menu-list-item-info span{
        font-size:12px;
    }
    .notification-menu-list-item-date{
        font-size:10px;
    }
    .notification-menu-list-body hr{
        margin:8px 0 11px 0;
    }
    .notification-menu-list .view-all-button{
        margin:10px 0;
    }
    .notification-menu-list .close-dialog-button{
        position:static!important;
        padding:0;
    }
    .notification-menu-list .MuiPopover-paper{
        top:70px!important;
    }
}

.not_scrollable {
    overflow: hidden;
}

#root > div {
    max-width: 100%;
    height: unset!important;
}

#root > div:nth-of-type(1) > div:nth-of-type(1) {
    display: none;
}

#main-container {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-flow: row nowrap;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
  }

#main-container .content-area {
    flex: 1 1 auto;
    max-width: 100%;
    position: relative;
    min-width: 0;
}

#main-container .content-area .radius-background {
    position: absolute;
    left: -1px;
    top: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#main-container .content-area .radius-background-2 {
    position: absolute;
    left: -1px;
    bottom: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
}

#main-container .content-area .content-area-wrapper {
    background-color: #fff;
    padding-left: 26px;
    padding-right: 26px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    min-height: 100vh;
    min-height: calc(1vh * 100);
    min-height: calc(var(--vh, 1vh) * 100);
    z-index: 2;
    position: relative;
    padding-bottom: 50px;
}

#main-container.__fullwidth .content-area.content-area-wrapper {
    padding-right: unset;
}

/* Responsive Designs */
@media screen and (max-width: 1024px) {
    #main-container .content-area .radius-background,
    #main-container .content-area .radius-background-2 {
        display: none!important;
    }

    #main-container .content-area .content-area-wrapper {
        padding: 0;
    }

    #main-container .content-area .content-area-wrapper .content-section {
        padding: 20px 28px;
    }

    #main-container:not(.__has-page-header) .content-area .content-area-wrapper {
        padding-top: 64px;
        min-height: calc(100vh - 64px);
        min-height: calc(calc(1vh * 100) - 64px);
        min-height: calc(calc(var(--vh, 1vh) * 100) - 64px);
    }

    #main-container.__has-page-header .content-area .content-area-wrapper {
        padding-top: 122px;
        padding-bottom:130px;
    }
}

@media screen and (max-width: 767px) {
    #main-container .content-area .content-area-wrapper .content-section {
        padding: 20px 16px;
    }
    .landing-page-main-container#main-container.__has-page-header .content-area .content-area-wrapper {
        padding-bottom:0;
    }
    .shortcuts-container .tab-menu{
        display:none!important;
    }
}
.bottom-menu-container{
    box-shadow: 0 -4px 6px 0 rgba(0,0,0,0.1);
    background-color: #f9f8fd;
    height:80px;
    width:100%;
    position:fixed;
    bottom:0;
    z-index:2;
    display:flex;
    align-items:center;
    justify-content:center;
    grid-gap:70px;
    gap:70px;
    padding:10px;
}

.bottom-menu-button{
    font-size:14px!important;
    font-weight:500!important;
    text-transform:capitalize!important;
    padding:20px 10px;
    line-height:inherit!important;
    color:#3f526d;
}

.bottom-menu-button img{
    width:24px;
    height:24px;
}

.bottom-menu-button .MuiButton-label{
    display:flex;
    flex-direction:column;
    align-items:center;
}

.bottom-menu-button .MuiButton-startIcon{
    margin-bottom:6px;
    margin-right:0;
}

.menu-plus-button{
    height:58px;
    width:58px;
    border-radius:50%;
    margin-top:-70px!important;
}

.menu-plus-button img{
    width:26px;
    height:26px;
}

.plus-list-container{
    padding:4px 37px!important;
    display:flex;
    align-items:center;
    grid-gap:16px;
    gap:16px;
}

.plus-list .MuiPopover-paper{
    box-shadow: 0 0 4px 0 rgba(127, 108, 252, 0.5);
    border-radius:30px;
}

.plus-list .MuiMenu-list{
    padding:0;
}

.plus-list img{
    height:20px;
    width:20px;
}

.plus-list li{
    font-size:12px;
    display:flex;
    flex-direction:column;
    align-items:center;
    color:white;
    text-transform:capitalize!important;
}

.more-list img{
    width:26px;
    height:26px;
    margin-right:12px;
}

.more-list svg{
    margin-right:12px;
}

.more-list li{
    font-size:14px;
    padding:20px;
    color:#3f526d;
    text-transform:capitalize!important;
}

.more-list .MuiPopover-paper{
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    width:180px;
    top:unset!important;
    bottom:100px;
}

@media(min-width:1024px){
    .bottom-menu-container{
        display:none!important;
    }
}

@media(max-width:768px){
    .bottom-menu-container{
        display:none!important;
    }
}



#gallery-view .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-right: 8px;
}

#gallery-view .done-button {
    width: 36px;
    flex: 0 0 36px;
    max-width: 36px;
    min-width: unset;
}

#gallery-view .gallery-divider {
    margin: 20px 0;
}

#gallery-view .images-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -8px;
}

#gallery-view .images-wrapper .gallery-view-item {
    margin-right: 8px;
    margin-bottom: 8px;
    color: white;
    position: relative;
    width: calc(33.3% - 8px);
}

#gallery-view .images-wrapper .gallery-view-item > span {
    width: 100%;
    height: 100%;
}

#gallery-view .images-wrapper .gallery-view-item img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

#gallery-view .images-wrapper .gallery-view-item .overlay {
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#gallery-view .images-wrapper .gallery-view-item .overlay .overlay-icon {
    color: #fff;
    font-size: 100px;
    text-shadow: rgba(0, 0, 0, 0.17);
}

@media(max-width:768px){
    #gallery-view .images-wrapper .gallery-view-item{
        width: calc(50% - 8px);
        height: auto!important;
        top: 0!important;
        padding:0;
        margin:4px;
    }
    #gallery-view .images-wrapper .gallery-view-item .overlay .overlay-icon{
        font-size:50px;
    }
    #gallery-view .top-section button{
        font-size:10px!important;
    }
    #gallery-view .done-button {
        width: 24px;
        flex: 0 0 24px;
        max-width: 24px;
        padding:0;
        margin:4px;
    }
    #gallery-view .gallery-divider{
        margin:10px 0;
    }
    #gallery-view .images-wrapper{
        margin-right:0;
    }
}

#capture-photo-view {

}

#capture-photo-view .camera video {
  max-width: 100%;
}

#capture-photo-view .take-photo-button {
  margin: 0 auto;
  display: block;
  margin-top: 30px;
  width: 280px;
  max-width: 100%;
  text-transform: unset;
  font-weight: 600;
}

#capture-photo-view #canvas{
  display: none;
}

.add-to-dialog-wrapper {
    width: 529px;
    max-width: 100%;
}

.add-to-dialog-wrapper[data-view="GALLERY"],
.add-to-dialog-wrapper[data-view="CAMERA"] {
    width: 900px;
}

.add-to-dialog-wrapper .dialog-title {
    text-align: center;
    margin-top: -6px;
}

.add-to-dialog-wrapper .dialog-title h6 {
    color: #3f526d;
    font-size: 18px;
    font-weight: bold;
}

.add-to-dialog-wrapper .dialog-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    min-height: 240px;
    padding: 20px 50px;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button {
    text-transform: unset;
    width: 170px;
    max-width: 170px;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button > .MuiButton-label{
    flex-wrap: wrap;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button > .MuiButton-label .MuiButton-startIcon {
    margin-left:0;
    margin-right: 0;
}

.add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button .button-text {
    flex: 1 1 100%;
    max-width: 100%;
    margin-top: 16px;
    color: rgba(63, 82, 109, 0.6);
    line-height: 30px;
    font-weight: 600;
}

@media(max-width:1024px){
    .add-to-dialog-wrapper .dialog-content{
        min-height:220px;
    }
    .add-to-dialog-wrapper .dialog-title h6{
        font-weight:500;
    }
    .add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button .button-text{
        font-size:14px;
        margin-top:27px;
    }
}

@media(max-width:768px){
    .add-to-dialog-wrapper{
        width:unset;
    }
    .add-to-dialog-wrapper .dialog-title h6{
        font-size:12px;
    }
    .add-to-dialog-wrapper .dialog-title{
        margin-top:7px;
    }
    .add-to-dialog-option-button img{
        width:38px;
        height:38px;
    }
    .add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button .button-text{
        font-size:12px;
        font-weight:500;
        line-height:2.5;
        margin-top:6px;
        white-space:nowrap;
    }
    .add-to-dialog-wrapper .dialog-content{
        min-height:unset;
        padding:20px 10px;
    }
    .view-picker{
        display:flex;
        align-items:center;
    }
    .add-to-dialog-wrapper .dialog-content .add-to-dialog-option-button{
        width:unset;
        max-width:unset;
    }
}

.dialog-container .MuiDialogContent-root{
    padding:0
}

.dialog-container .MuiPaper-root{
    border-radius:12px!important;
}

.close-dialog-button{
    position:absolute!important;
    top:0;
    right:0;
}

.download-dialog-button{
    position:absolute!important;
    top:30px;
    right:30px;
    border-radius:6px!important;
    border: solid 0.5px rgba(63, 82, 109, 0.24)!important;
    width:44px;
    height:44px;
}

.dialog-container .MuiBackdrop-root{
    background-color:rgba(0, 0, 0, 0.25);
}

.download-dialog-button img{
    width:14px;
}

#image-carousel-dialog .MuiDialog-paper{
    background-color: transparent;
    max-width: 80vw;
    box-shadow: none;
    width: 100%;
    position: relative;
    overflow-y: visible;
}

#image-carousel-dialog .dialog-container{
    padding-top: 0;
    overflow-y: visible;
}

#image-carousel-dialog .remove-image-button {
    position: absolute;
    left:50%;
    top:0px;
    transform: translate(-50%, -100%);
    -webkit-transform: translate(-50%, -100%);
    -moz-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    -o-transform: translate(-50%, -100%);
}

#image-carousel-dialog .remove-image-button svg {
    font-size: 35px;
    color: #fff;
}

#image-carousel-dialog .close-dialog-button {
    position: absolute;
    right: 90px;
    top:0;
    transform: translateY(-100%);
}

#image-carousel-dialog .close-dialog-button svg {
    font-size: 35px;
    color: #fff;
}

#image-carousel-dialog .carousel-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
}

#image-carousel-dialog .back-button,
#image-carousel-dialog .next-button {
    width: 44px;
    min-width: 44px;
    max-width: 44px;
    flex:0 0 44px;
    margin-right: 0!important;
}

#image-carousel-dialog .back-button svg,
#image-carousel-dialog .next-button svg {
    font-size: 44px;
}

#image-carousel-dialog .back-button:not(.Mui-disabled) svg,
#image-carousel-dialog .next-button:not(.Mui-disabled) svg {
    color: #fff;
    font-size: 44px;
}

#image-carousel-dialog .next-button svg {
    transform: rotate(180deg);
}

#image-carousel-dialog .images-wrapper {
    margin-left: 40px;
    margin-right: 40px;
    flex: 1 1 auto;
    max-width: 100%;
}

#image-carousel-dialog .images-wrapper .image-wrapper {
    width: 100%;
    height: 60vh;
    height: calc(1vh * 60);
    height: calc(var(--vh, 1vh) * 60);
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

#target-group-wrapper .target-group-selectbox {
    color: #3f526d;
    border-radius: 6px!important;
    background-color: #f9f8fd;
}

#target-group-wrapper .target-group-selectbox > .MuiSelect-select{
    padding-top: 13px;
    padding-bottom: 12px;
}

#target-group-wrapper .target-group-selectbox.__small > .MuiSelect-select {
    padding-top: 10px;
    padding-bottom: 9px;
}

#target-group-wrapper .target-group-selectbox .selectbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    color: #3f526d;
    font-size: 14px;
    font-weight: 600;
}

#target-group-wrapper .target-group-selectbox .selectbox-wrapper .selectbox-icon {
    margin-right: 10px;
    font-size: 14px;
    color: #3f526d;
    font-size: 16px;
}

.target-group-menu-item .menu-item-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: row nowrap;
}


.target-group-menu-item .menu-item-wrapper .menu-icon {
    opacity: 0.5;
    margin-right: 8px;
}

.target-group-menu-item .menu-item-wrapper .texts b {
    display: block;
    font-size: 14px;
}

.target-group-menu-item .menu-item-wrapper .texts small {
    font-size: 12px;
    opacity: 0.5;
}

@media(max-width:1024px){
    #target-group-wrapper .target-group-selectbox .selectbox-wrapper{
        font-weight:500;
        font-size:12px;
    }
}

@media(max-width:768px){
    #target-group-wrapper>div>svg{
        top:unset;
        font-size:16px;
        right:2px;
    }
    #create-content-widget #target-group-wrapper .target-group-selectbox .selectbox-wrapper .selectbox-icon{
        font-size:10px;
        margin-right:8px;
    }
}


#create-content-footer-section-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
}

#create-content-footer-section-wrapper .left-side {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

#create-content-footer-section-wrapper .left-side .section-item {
    font-size: 14px;
    text-transform: none;
    color: #3f526d;
    font-weight: normal;
}

#create-content-footer-section-wrapper .left-side hr {
    margin: 0 20px;
    height: 22.5px;
    align-self: center;
}

#create-content-footer-section-wrapper .left-side .section-item img {
    margin-right: 6px;
}

#create-content-footer-section-wrapper .right-side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

#create-content-footer-section-wrapper .right-side .action-button {
    margin-left: 14px;
    text-transform: none;
    min-width: 100px;
    border-radius: 6px;
}

@media(max-width:1024px){
    #create-content-footer-section-wrapper .left-side .section-item{
        font-size:12px;
    }
    #create-content-footer-section-wrapper .left-side .section-item img{
        width:18px;
        height:18px;
    }
    #create-content-footer-section-wrapper .right-side .action-button{
        font-size:14px;
        font-weight:500;
    }
    #create-content-footer-section-wrapper .left-side hr{
        margin:0 11px;
    }
}

@media(max-width:768px){
    #create-content-footer-section-wrapper .right-side .action-button{
        min-width:unset;
    }
    #create-content-footer-section-wrapper .left-side hr{
        margin:0;
    }
    #create-content-footer-section-wrapper .left-side{
        margin:0 -4px;
    }
}

.add-file-dialog-body{
    display:flex;
    flex-direction:column;
    margin:10px 21px;
}

.add-file-dialog-body img{
    width:70px;
}

.add-file-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color:#3f526d;
    margin:0;
}

.add-file-dropzone-container{
    /*padding: 25px 255px 67px 91px;*/
    border-radius: 8px;
    border: dashed 0.5px rgba(37, 56, 115, 0.5);
    background-color: #f9f8fd;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    padding:25px 92px 20px 93px;
    margin-top:20px;
    margin-bottom:40px;
}
/*git commit -m "[finishes #798344] Add AnswerDialog | ShareToFeed | SendToChat dialogs, Badges tab data, discussion forum tab data design, some improvements on components, Add functionality to add comments"*/
.add-file-dropzone-container p{
    font-size: 16px;
    color: #253873;
    margin-top:25px;
}

.add-file-dialog-body button{
    text-transform:none!important;
    line-height:normal!important;
}

.add-file-button{
    font-size: 16px!important;
    font-weight: 600!important;
    padding:13px 39px!important;
    border-radius:8px!important;
}

.add-file-upload-buttons{
    display:flex;
    grid-gap:10px;
    gap:10px;
    margin-bottom:22px;
}

.upload-file-button, .cancel-file-button{
    font-size: 18px!important;
    font-weight: 600!important;
    padding:15px 48px!important;
    border-radius:8px!important;
    width: 124px;
    height: 48px;
}

.cancel-file-button{
    color:#3f526d!important;
}

.file-list{
    margin-bottom:40px;
}

.file-list-item{
    display:flex;
    justify-content: space-between;
    align-items:center;
}

.file-list-item div{
    display:flex;
    align-items:center;
}

.file-list-item .file-name,
.file-list-item .file-upload-time{
    font-family: OpenSans,sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.file-list-item .file-name{
    font-size: 18px;
    margin-left:20px;

}

.file-list-item .file-upload-time{
    opacity: 0.6;
    font-size: 14px;
    margin-right:18px;
}

.file-list-item .file-icon{
    width:32px;
    height:32px;
}

@media(max-width:1024px){
    .add-file-button,
    .add-file-dialog-title,
    .upload-file-button, .cancel-file-button{
        font-size:14px!important;
        font-weight:500!important;
    }
    .upload-file-button,.cancel-file-button,
    .add-file-button{
        width:120px;
        height:40px;
    }
    .add-file-dropzone-container p{
        font-size:12px!important;
    }
    .add-file-dropzone-container{
        margin-bottom:20px;
    }
    .add-file-dropzone-container .MuiButton-label{
        white-space:nowrap;
    }
    .add-file-dialog-body .file-list-item .file-name{
        font-size:14px;
        font-weight:500;
        margin-left:12px;
    }
    .add-file-dialog-body .file-list-item .file-upload-time{
        font-size:12px;
    }
}

@media(max-width:768px){
    .add-file-dialog-title{
        font-size:12px!important;
    }
    .add-file-button, .upload-file-button, .cancel-file-button{
        width:84px;
        height:28px;
        font-size:10px!important;
    }
    .add-file-dropzone-container p{
        font-size:10px!important;
        color:#253873;
        margin-top:10px;
        margin-bottom:0;
        text-align:center;
        opacity:0.5;
    }
    .add-file-dropzone-container{
        margin-top:10px;
        margin-bottom:16px;
        padding:10px;
    }
    .add-file-upload-buttons{
        justify-content:center;
        margin-bottom:0;
    }
    .add-file-dialog-body{
        margin:7px 17px 17px 17px;
    }
    .add-file-dialog-body .file-list-item .file-name{
        font-size:12px;
        margin-left:8px;
    }
    .add-file-dialog-body .file-list-item .file-upload-time{
        font-size:10px;
        text-align:right;
        margin-right:0;
    }
    .add-file-dialog-body .file-list-item .delete-file-button{
        margin-right:-10px;
    }
    .add-file-dialog-body .file-list-item .file-icon{
        width:26px;
        height:26px;
    }
}


.top-students-menu-container{
    padding: 30px 5px 40px 40px;
    border-top-left-radius: 40px;
    flex:0 0 250px;
    max-width:250px;
    width: 250px;
    margin-right:-26px;
    margin-left:40px;
    z-index: 10;
    overflow:hidden;
}

.top-students-menu-container h3,
.top-students-card h4,
.top-students-card span{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin:0;
}

.top-students-menu-container h3{
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom:36px;
}

.top-students-card{
    display:flex;
    align-items:center;
    grid-gap:15px;
    gap:15px;
    margin-bottom:28px;
}

.top-students-card img{
    width:46px;
    height:46px;
    border-radius:50%;
    border: solid 2px #fff;
}

.top-students-card h4{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.top-students-card span{
    font-size: 12px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.76);
}

.top-students-menu-container .no-data-section {
    font-size: 16px;
    color: white;
    opacity: 0.6;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 26px;
    margin-top: -36px;
    height: 100%;
}

@media screen and (max-width: 1024px) {
    .top-students-menu-container.__mobile-view {
        position: fixed;
        right: 18px;
        bottom: 60px;
        width: 225px;
        max-width: 225px;
        flex: 0 0 225px;
        max-height: 80vh;
        max-height: calc(1vh * 100);
        max-height: calc(var(--vh, 1vh) * 100);
        border-radius: 40px;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        -ms-border-radius: 40px;
        -o-border-radius: 40px;
        box-sizing: border-box;
        margin:0;
        padding:0;
        overflow: hidden;
    }

    .top-students-menu-container.__mobile-view:not(.__collapsed) {
        border-radius: 14px;
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        -ms-border-radius: 14px;
        -o-border-radius: 14px;
}

    .top-students-menu-container.__mobile-view .top-students-list {
        overflow-y: auto;
        max-height: calc(100% - 60px);
        width: 100%;
        width: calc(100% + 16px);
        margin-top: 10px;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        -webkit-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        -moz-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        -ms-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        -o-transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    }

    .top-students-menu-container.__mobile-view.__collapsed {
        width: 160px;
        max-width: 160px;
        flex: 0 0 160px;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -ms-border-radius: 20px;
        -o-border-radius: 20px;
        padding:0;
        height: 40px;
    }

    .top-students-menu-container.__mobile-view.__collapsed .top-students-list {
        max-height: 0;
    }

    .top-students-menu-container.__mobile-view:not(.__collapsed) .top-students-list {
        max-height: 80vh;
        max-height: calc(80vh - 60px);
        max-height: calc(calc(1vh * 80) - 60px);
        max-height: calc(calc(var(--vh, 1vh) * 80) - 60px);
    }

    .top-students-menu-container.__mobile-view .top-students-list .top-students-card {
        padding: 0 16px;
    }

    .top-students-menu-container.__mobile-view .title-button {
        text-transform: unset;
        letter-spacing: 0;
        font-size: 12px;
        color:#fff;
        padding-top: 15px;
    }

    .top-students-menu-container.__mobile-view .title-button .title-button-icon {
        font-size: 24px;
        margin-right: 14px;
    }

    .top-students-menu-container.__mobile-view.__collapsed .title-button {
        padding-top: 0;
        height: 100%;
    }

    .top-students-menu-container.__mobile-view:not(.__collapsed) .title-button {
        font-size: 14px;
        justify-content: flex-start;
    }

    .top-students-menu-container.__mobile-view:not(.__collapsed) .title-button .title-button-icon {
        margin-right: 18px;
        font-size: 30px;
    }
}

@media screen and (max-width: 767px) {
    .top-students-menu-container.__mobile-view {
        bottom: 33px;
    }
}
.border-colored-text-field-wrapper{
    position:relative;
    display:flex;
    align-items:center;
    width:100%;
}

.border-colored-text-field-container .border-colored-text-field-icon{
    position:absolute;
    right:20px;
}

.student-search-form{
    margin-bottom:20px;
}

.student-search-input,
.text-field-search-input {
    border-radius:6px!important;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    width:100%;
    padding:15px 55px 15px 15px;
    border-style:solid;
}

.student-search-input input,
.text-field-search-input input{
    padding:18px 20px!important;
}


.student-search-input input::-moz-placeholder, .text-field-search-input input::-moz-placeholder {
    color: rgba(63, 82, 109,0.5)!important;
}


.student-search-input input::placeholder,
.text-field-search-input input::placeholder {
    color: rgba(63, 82, 109,0.5)!important;
}

.border-colored-text-field-container .border-colored-text-field-icon img.student-search-icon{
    width:20px;
    height:20px;
    margin-right:5px;
}

@media screen and (max-width: 576px) {
    .border-colored-text-field-container .text-field-search-input {
        height: 32px;
        font-size: 12px;
        border-color: #3f526d!important;
        border-width: 0.5px!important;
        padding: 9px 20px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }

    .border-colored-text-field-container .search-icon {
        font-size: 18px;
    }
}
.friend-list-block .content-area-wrapper {
    padding-bottom: 0 !important;
}

.friend-list-block .user-list-wrapper .user-list-item-card {
    border: 1px solid rgba(63, 82, 109, 0.5);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 20px 16px 14px 16px;
    background-color: #fff;
    text-transform: unset;
    width: 100%;
}

.friend-list-block .friend-list-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 40px 0;
    flex-wrap: wrap;
    height: calc(100% - 160px);
}

.friend-list-block .friend-list-placeholder span {
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin-top: 80px;
    color: #3f526d;
    font-size: 14px;
}

.friend-list-block .student-list-wrapper.__has-users {
    position: relative;
    padding-bottom: 25px;
}

.friend-list-block .pagination-wrapper {
    position: absolute;
    right: 0;
    bottom: 25px;
}

.friend-list-block .student-filter-select-boxes {
    display: flex;
    align-items: center;
}

.friend-list-block .student-filter-select-box {
    width: 200px;
    position: relative;
}

.friend-list-block .student-filter-select-box:first-child {
    margin-right: 14px;
}

.friend-list-block .student-filter-select-box .select-word {
    margin-right: 4px;
}

.friend-list-block .student-filter-select-box .progress-icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: wait;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.friend-list-block .student-filter-select-box fieldset {
    border: none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
}

.friend-list-block .student-filter-select-box .MuiSelect-select {
    border-radius: 8px !important;
    padding: 15px 20px;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
}

.friend-list-block .student-filter-select-boxes .MuiInputLabel-outlined {
    transform: translate(15px, 18px) scale(1) !important;
    color:#3f526d;
    font-weight: 500;
    -webkit-transform: translate(15px, 18px) scale(1) !important;
    -moz-transform: translate(15px, 18px) scale(1) !important;
    -ms-transform: translate(15px, 18px) scale(1) !important;
    -o-transform: translate(15px, 18px) scale(1) !important;
}

.friend-list-block .student-filter-select-boxes .MuiInputLabel-outlined.MuiFormLabel-filled {
    display: none;
}

.friend-list-block .student-search-form {
    margin-bottom: 20px !important;
    width: 100%;
}

.friend-list-block .student-search-input {
    border-radius: 6px !important;
    /*opacity: 0.5;*/
    font-family: Open Sans, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109, 0.5) !important;
}

.friend-list-block .student-search-input input {
    padding: 18px 20px !important;
}

.friend-list-block .student-search-input img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.friend-list-block .student-list-container {
    display: flex;
    flex-wrap: nowrap
}

.friend-list-block .student-list-container .user-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    width: calc(100% + 20px);
}

.friend-list-block .student-list-container .user-list-item {
    box-sizing: border-box;
    padding: 10px;
    min-width: 236px;
}

.friend-list-block .student-list-filters-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.friend-list-block .student-list-filters {
    display: flex;
    align-items: center;
}

.friend-list-block .student-list-filters .page-title {
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-right: 78px;
}

@media screen and (min-width:1441px) {
    .friend-list-block .student-list-container .user-list-item {
        flex: 1 1 25%;
        max-width: 33.33%;
    }
}

@media screen and (max-width:1440px) {
    .friend-list-block .student-list-container .user-list-item {
        flex: 1 1 25%;
        max-width: 33.33%;
    }
}

@media screen and (max-width:1366px) {
    .friend-list-block .student-list-container .user-list-item {
        flex: 1 1 33.33%;
        max-width: 50%;
    }
}

@media screen and (min-width: 1025px) {
    .friend-list-block .student-list-wrapper {
        min-height: calc(100vh - 228px);
        padding-bottom: 25px;
    }

    .friend-list-block .content-wrapper {
        padding-bottom: 85px;
    }
}

@media screen and (max-width: 1024px) {
    .friend-list-block .content-wrapper {
        padding-bottom: 85px;
    }

    .friend-list-block .student-list-filters .page-title {
        display: none;
    }

    .friend-list-block .student-list-container .user-list-item {
        flex: 1 1 33.33%;
        max-width: 33.33%;
    }

    .friend-list-block .student-list-filters-container .my-profile-button {
        height: 40px;
        width: 140px;
        font-size: 14px !important;
        letter-spacing: 0;
        font-weight: 500;
        white-space: nowrap;
    }

    .friend-list-block .student-filter-select-boxes .student-filter-select-box {
        width: 160px;
    }

    .friend-list-block .student-filter-select-boxes .student-filter-select-box .MuiSelect-select {
        padding: 11px 20px 10px 20px;
    }

    .friend-list-block .student-filter-select-boxes .student-filter-select-box .MuiInputLabel-outlined {
        transform: translate(15px, 12px) scale(1) !important;
        -webkit-transform: translate(15px, 12px) scale(1) !important;
        -moz-transform: translate(15px, 12px) scale(1) !important;
        -ms-transform: translate(15px, 12px) scale(1) !important;
        -o-transform: translate(15px, 12px) scale(1) !important;
    }

    .friend-list-block .student-search-input {
        font-size: 12px;
        border-color: #3f526d!important;
        border-width: 0.5!important;
    }

    .friend-list-block .student-list-wrapper:not(.__has-users) .student-search-form {
        display: none;
    }
}

@media screen and (max-width:767px) {
    .friend-list-block .student-list-container .user-list-item {
        flex: 1 1 50%;
        max-width: 50%;
    }

    .friend-list-block .student-filter-select-box > label {
        font-size: 12px;
        font-weight: 500;
    }
}

@media screen and (max-width:576px) {
    .friend-list-block .student-list-filters-container {
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .friend-list-block .student-list-filters-container .student-list-filters {
        width: 100%;
        max-width: 100%;
        flex: 1 1 100%;
        order: 2;
    }

    .friend-list-block .student-list-filters-container .student-list-filters .student-filter-select-boxes {
        flex: 1 1 100%;
        max-width: 100%;
        width: 100%;
        margin-bottom: 0;
    }

    .friend-list-block .student-list-filters-container .my-profile-button {
        width: 100%;
        flex: 1 1 100%;
        max-width: 100%;
        font-size: 12px!important;
        font-weight: 500!important;
        height: 38px!important;
        margin-bottom: 10px;
        order: 1;
    }

    .friend-list-block .border-colored-text-field-container .border-colored-text-field-icon img.student-search-icon {
        width: 16px;
        height: 16px;
    }

    .friend-list-block .student-filter-select-boxes {
        justify-content: space-between;
    }

    .friend-list-block .student-filter-select-boxes .student-filter-select-box {
        flex: 0 0 48%;
        max-width: 48%;
        width: unset;
        margin-right: 0;
    }

    .friend-list-block .student-filter-select-boxes .student-filter-select-box > div {
        font-size: 12px;
    }

    .friend-list-block .user-list-wrapper .user-list-item {
        min-width: unset;
    }

    .friend-list-block .student-search-input {
        height: 32px;
        font-size: 10px;
    }

    .friend-list-block .student-filter-select-boxes .student-filter-select-box .select-word {
        display: none;
    }

    .friend-list-block .student-filter-select-boxes .student-filter-select-box .MuiSelect-select {
        padding: 9px 0px 9px 12px;
    }

    .friend-list-block .user-list-wrapper .user-list-item-card {
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }
}

@media screen and (max-width:420px) {
    .friend-list-block .student-list-container .user-list-item {
        flex: 1 1 100%;
        max-width: 100%;
    }
}

.custom-pagination .MuiPagination-ul li:not(:last-child) {
  margin-right: 12px;
}

.custom-pagination .MuiPagination-ul li .page-button {
  width: 44px;
  height: 44px;
  font-size: 16px;
  min-width: unset;
}

.custom-pagination .MuiPagination-ul li .page-button:not(.__selected) {
  color: #3f526d;
}

.custom-pagination .MuiPagination-ul li .previousButton,
.custom-pagination .MuiPagination-ul li .nextButton {
  height: 44px;
  width: 124px;
  font-size: 16px;
  text-transform: unset;
}

.custom-pagination .MuiPagination-ul li .previousButton svg,
.custom-pagination .MuiPagination-ul li .nextButton svg {
  font-size: 24px;
}

.custom-pagination .MuiPagination-ul li .nextButton svg {
  transform: rotate(180deg);
}

.custom-pagination .MuiPagination-ul li button:disabled {
  background-color: #fff;
  border: 1px solid #3a60d7;
  opacity: 0.4;
  color: #3f526d;
}


@media screen and (max-width: 1024px) {
  .custom-pagination .MuiPagination-ul li .page-button {
    width: 36px;
    height: 36px;
    font-size: 14px;
  }

  .custom-pagination .MuiPagination-ul li .previousButton,
  .custom-pagination .MuiPagination-ul li .nextButton {
    height: 36px;
    width: 110px;
    font-size: 14px;
  }
}
.generic-card {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1)!important;
    padding: 20px;
    border-radius: 8px;
  }
  
  .generic-card .generic-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  
  .generic-card .generic-card-subtitle {
    color: #3f526d;
    font-weight: 600;
    font-size: 18px;
  }
  
  .generic-card .card-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: rgba(63, 82, 109, 0.12);
  }

.avatar-field {
    padding: 3px;
    background-color: #f9f8fd;
    border-width: 2px;
    border-style: solid;
    margin-right: 15px;
}

#page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}

#page-header .left-side {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#page-header .left-side .page-header-button {
  color: #3f526d;
  font-weight: 600;
  text-decoration: none;
  text-transform: none;
  font-size: 20px;
}

#page-header .left-side .page-header-button .go-back-icon {
  font-size: 22px;
}

#page-header .left-side .page-header-button .page-header-button-text {
  font-weight: 600;
  font-size: 19px;
}

@media(max-width:1024px){
  #page-header .left-side .page-header-button .page-header-button-text{
    font-size:16px!important;
    font-weight:500;
  }
  #page-header .left-side .page-header-button .go-back-icon{
    font-size:18px;
  }
}

* {
    font-family: Open Sans,sans-serif;
}
#mark-all-notifications-as-read-button {
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
    height: 44px;
}
  
#notifications-page .search-area .search-area-textfield {
    height: 60px;
    width:100%;
    border-radius:8px;
    border-style:solid;
    border-width:1px;
    padding:0 55px 0 20px;
    font-size: 18px;
}

#notifications-page .search-area .search-area-textfield::-moz-placeholder {
    font-weight: 600;
    color: rgba(63, 82, 109, 0.5);
}

#notifications-page .search-area .search-area-textfield::placeholder {
    font-weight: 600;
    color: rgba(63, 82, 109, 0.5);
}

#notifications-page .search-area .search-icon {
    font-size: 34px;
}
  
#notifications-page .notification-list {
    margin: 20px 0 40px 0;
}
  
#notifications-page .notification-list .notification-group {
    margin-top: 20px;
}
  
#notifications-page .notification-list .notification-group .group-title {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}
  
#notifications-page .notification-list .notification-group .group-title .group-title-text{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
}
  
#notifications-page .notification-list .notification-group .group-title .clear-all-button {
    font-size: 18px;
    padding-right: 0;
    text-decoration: underline;
    text-transform: unset;
    font-weight: 600;
}
  
#notifications-page .notification-list .item-list {
    
}
  
#notifications-page .notification-list .item-list .notification-item:not(:last-child) {
    margin-bottom: 10px;
}
  
#notifications-page .notification-list .item-list .notification-item {
    border-radius: 6px;
    padding: 14px 20px;
    position: relative;
    background-color: #f9f8fd;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-avatar {
    margin-top: 11px;
}
  
  /* CONTENTS */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section {
    padding-left: 20px;
    display: flex;
    flex-wrap: nowrap;
    flex: auto;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area {
    flex: auto;
    padding-right: 37px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area strong {
    font-weight: 600;
    font-size: 18px;
    color: #3f526d;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: #3f526d;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p mark {
    background-color: unset;
    color: #3a60d7;
}
  
  /* ATTACHMENTS */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-bottom: -10px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .attachment-item {
    padding: 10px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments a{
    text-decoration: none;
  
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button {
    color: #327095;
    text-transform: none;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .filename {
    font-size: 16px;
    font-weight: 600;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button .file-size {
    display: block;
    font-size: 12px;
    color: #79869f;
    text-align: left;
    font-weight: normal;
}
  
  /* Date and Remove Section */
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area b{
    color: #3f526d;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    position: relative;
    top: -4px;
}
  
#notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area .remove-button {
    position: absolute;
    right: 9px;
    bottom: 6px;
}
  
#notifications-page .pagination-wrapper {
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 1024px) {
    #mark-all-notifications-as-read-button {
        width: 160px;
        height: 38px;
        font-size: 12px;
    }

    #notifications-page {
        box-shadow: unset!important;
        border: 0;
        background-color: transparent;
        padding: 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    #notifications-page .notification-list .notification-group .group-title .group-title-text {
        font-size: 14px;
        font-weight: 500;
    }

    #notifications-page .notification-list .notification-group .group-title .clear-all-button {
        font-size: 14px;
    }

    #notifications-page .notification-list .notification-group .group-title {
        margin-bottom: 20px!important;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-avatar {
        width: 38px!important;
        height: 38px!important;
        border: 0!important;
        padding: 0!important;
        margin: 0;
        margin-top: 7px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area strong {
        font-size: 14px;
        font-weight: 500;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p {
        font-size: 14px;
        line-height: 22px;
        font-weight: normal;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area b {
        font-size: 12px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .filename {
        font-weight: 500;
        font-size: 14px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .attachment-item img {
        width: 18px;
        height: 22px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area .remove-button img {
        width: 16px;
        height: 16px;
    }
}

@media screen and (max-width: 576px) {
    #mark-all-notifications-as-read-button {
        height: 32px;
        width: 146px;
    }

    #notifications-page .notification-list .notification-group .group-title {
        margin-bottom: 3px!important;
    }

    #notifications-page .notification-list .notification-group .group-title .group-title-text {
        font-size: 12px;
    }

    #notifications-page .notification-list .notification-group .group-title .clear-all-button {
        font-size: 12px;
    }

    #notifications-page .notification-list .item-list .notification-item {
        border-bottom: 0.5px solid rgba(63, 82, 109, 0.28);
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 8px;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        background-color: transparent;
    }
    
    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-avatar {
        width: 28px!important;
        height: 28px!important;
        border: 0!important;
        padding: 0!important;
        margin: 0;
        margin-top: 7px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area {
        padding-right: 0;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .texts {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        align-items: center;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area strong {
        margin-right: 3px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area p {
        font-size: 12px;
        font-weight: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(100vw - 200px);
    }

    #notifications-page .search-area .search-area-textfield {
        height: 32px;
        font-size: 12px;
        border-color: #3f526d!important;
        border-width: 0.5px!important;
        padding: 9px 20px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
    }

    #notifications-page .search-area .search-icon {
        font-size: 18px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area b {
        position: absolute;
        top: 36px;
        left: 48px;
        font-size: 10px;
        font-weight: normal;
        letter-spacing: normal;
        line-height: normal;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .date-and-remove-area .remove-button {
        bottom: unset;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments {
        margin-top: 20px;
        margin-bottom: -8px;
        margin-left: -40px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .attachment-item {
        padding:0;
        flex: 1 1 100%;
        max-width: 100%;

    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments .filename {
        font-size: 12px;
    }

    #notifications-page .notification-list .item-list .notification-item .item-wrapper .item-content-section .content-area .attachments button .file-size {
        font-size: 10px;
    }
}
#main-container.all-stories-page-main-container .content-area-wrapper {
  padding-bottom: 0;
}

#main-container.all-stories-page-main-container .stories-page-placeholder {
  height: 100vh;
  height: calc(100vh - 150px);
  height: calc(calc(1vh * 100) - 150px);
  height: calc(calc(var(--vh, 1vh) * 100) - 150px);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
}

#main-container.all-stories-page-main-container .stories-page-placeholder span {
  flex: 1 1 100%;
  max-width: 100%;
  text-align: center;
  color: #3f526d;
  font-weight: bold;
  font-size: 24px;
  margin-top: 48px;
}

#main-container.all-stories-page-main-container .stories-page-placeholder img {
  max-width: 100%;
}

#all-stories-page>*>.generic-card {
  height: 100%;
}

#all-stories-page .all-stories-sider {
  flex: 0 0 290px;
  max-width: 290px;
  width: 290px;
  margin-right: 32px;
  height: 100vh;
  height: calc(100vh - 150px);
  height: calc(calc(1vh * 100) - 150px);
  height: calc(calc(var(--vh, 1vh) * 100) - 150px);
  max-height: 100%;
}

#all-stories-page .search-form {
  display: none;
}

#all-stories-page .mobile-stories {
  display: none;
}

#all-stories-page .all-stories-sider .generic-card {
  height: 100%;
  overflow-y: hidden;
}

#all-stories-page .all-stories-sider .generic-card-title button {
  text-transform: unset;
  font-size: 18px;
  letter-spacing: 0;
  color: #3f526d; 
}

#all-stories-page .all-stories-sider .generic-card .card-divider {
  margin-bottom: 12px;
}

#all-stories-page .all-stories-content {
  flex: auto;
  max-width: 100%;
}

#all-stories-page .active-stories-content-wrapper {
  height: calc(100vh - 150px);
  height: calc(1vh*100 - 150px);
  height: calc(var(--vh, 1vh)*100 - 150px);
}

#all-stories-page .active-stories-content-wrapper .generic-card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  height: 100%;
}

#all-stories-page .story-viewer-container {
  width: 500px;
  height: 100%;
  margin: 20px 30px;
  max-width: 100%;
}

#all-stories-page .story-viewer-container .story-viewer-wrapper {
  flex: auto;
  max-width: 100%;
}

#all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer {
  height: 100%;
  padding: 0;
}

#all-stories-page .story-viewer .story-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
}

#all-stories-page .story-viewer .story-wrapper .story-head {
  position: absolute;
  left: 0;
  top: 0;
  padding: 14px 10px;
  width: 100%;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper {
  width: 100%;
  width: calc(100% + 88px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-dots {
  top: -90px;
  bottom: unset;
  display: flex!important;
  padding: 0 50px;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-dots li {
  height: 6px;
  background-color: #d8d8d8;
  opacity: 0.3;
  border-radius: 3px;
  flex: auto;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-dots li.slick-active {
  opacity: 1;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-list {
  width: 100%;
  height: 100%;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-slide {
  height: calc(100% - 100px);
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-prev {
  top: unset;
  transform: unset;
  -webkit-transform: unset;
  -moz-transform: unset;
  -ms-transform: unset;
  -o-transform: unset;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-arrow {
  width: 44px;
  height: 44px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.13);
  padding: 0;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-arrow:before {
  content: ''!important;
  display: none;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-arrow svg {
  font-size: 32px;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-arrow:not(.slick-disabled) svg {
  color: #3f526d;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-arrow.slick-disabled svg {
  color: #d3d3d3;
}

#all-stories-page .story-viewer .story-wrapper .slider-wrapper .next-icon {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
}

#all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-content {
  background-size: contain;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

#all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .favorite-btn {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

#all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .favorite-btn svg {
  font-size: 28px;
}

/* #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-content img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  } */

/* STORY OWNER */
#all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

#all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner .close-button {
  display: none;
}

#all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner .avatar {
  width: 44px;
  height: 44px;
  margin-right: 14px;
}

#all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner h6 {
  color: #3f526d;
  font-size: 16px;
  font-weight: 600;
}

/* REPLY SECTION */

#all-stories-page .story-viewer-container .reply-wrapper {
  margin-top: 30px;
}

#all-stories-page .story-viewer-container .reply-wrapper .textarea input {
  height: 46px;
  font-size: 14px;
  padding: 0 20px;
  font-weight: 600;
}

#all-stories-page .story-viewer-container .reply-wrapper .textarea input::-moz-placeholder {
  color: #d1d5dd;
}

#all-stories-page .story-viewer-container .reply-wrapper .textarea input::placeholder {
  color: #d1d5dd;
}

#all-stories-page .story-viewer-container .reply-wrapper .border-colored-text-field-container {
  width: 100%;
}

#all-stories-page .story-viewer-container .reply-wrapper .textarea fieldset {
  border-radius: 25px;
}

#all-stories-page .story-viewer-container .reply-wrapper .border-colored-text-field-container .favorite-btn{
  display: none;
}

#all-stories-page .story-viewer-container .reply-wrapper .textarea .reply-area-smile-button {
  margin-right: -12px;
}

#all-stories-page .story-viewer-container .reply-wrapper .textarea .reply-area-smile-button svg {
  opacity: 0.48;
}

#all-stories-page .emoji-textarea-container {
  position:relative;
  display:flex;
  align-items:center;
  width:100%;
}

#all-stories-page .emoji-textarea-container .textarea{
  height: 46px;
  font-size: 14px;
  padding: 0 20px;
  font-weight: 600;
  border-radius: 25px;
  outline:none;
  width:100%;
  border-width:1px;
  border-style:solid;
}

@media screen and (min-width: 1025px) {
  #all-stories-page .all-stories-sider .generic-card-content {
    margin: 0 -20px;
    height: calc(100% - 40px);
    overflow-y: auto;
  }

  #all-stories-page .stories-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 20px;
    cursor: pointer;
    text-transform: none;
    width: 100%;
    border: 0;
    background-image: unset!important;
    background-color: #fff;
  }
  
  #all-stories-page .stories-item.__active {
    background-color: #f9f8fd;
  }
  
  #all-stories-page .stories-item:hover {
    /* background-color: rgba(0, 0, 0, 0.17); */
  }
  
  #all-stories-page .stories-item:not(:first-child) {
    margin-top: 14px;
  }
  
  #all-stories-page .stories-item .texts {
    text-align: left;
  }
  
  #all-stories-page .stories-item .texts strong {
    display: block;
    font-weight: 600;
    font-size: 18px;
    color: #3f526d;
  }
  
  #all-stories-page .stories-item .texts small {
    font-size: 12px;
    color: rgba(63, 82, 109, 0.6);
    font-weight: 600;
  }

  .all-stories-page-main-container .page-header-wrapper {
    display: none!important;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1100px) {
  #all-stories-page .story-viewer-container {
    width: 360px;
  }
}

@media screen and (max-width: 1024px) {
  .all-stories-page-main-container.__has-stories #all-stories-page{
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  .all-stories-page-main-container.__has-stories.__user-selected #all-stories-page {
    height: 100%;
  }

  .all-stories-page-main-container.__has-stories.__user-selected .page-header-wrapper{
    display: none;
  }

  #main-container.all-stories-page-main-container.__has-stories .content-section{
    padding: 0;
  }

  #main-container.all-stories-page-main-container.__has-stories.__user-selected .content-section{
    height: 100vh;
    height: calc(1vh * 100);
    height: calc(var(--vh, 1vh) * 100);
  }

  .all-stories-page-main-container .content-area-wrapper {
    padding-bottom: 0!important;
  }

  #main-container.all-stories-page-main-container.__has-stories.__user-selected .content-area-wrapper {
    padding-top: 0px!important;
  }

  #main-container.all-stories-page-main-container.__has-stories.__user-selected .content-area-wrapper .content-section{
    position: relative;
    z-index: 999;
    background-color: white;
  }

  #all-stories-page .all-stories-sider .generic-card-title,
  #all-stories-page .all-stories-sider .generic-card-title ~ hr {
    display: none;
  }

  #all-stories-page .all-stories-sider {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
    height: auto;
    margin-right: 0;
    padding: 28px;
    min-width: 100vw;
    transition: 300ms ease-in-out;
    -webkit-transition: 300ms ease-in-out;
    -moz-transition: 300ms ease-in-out;
    -ms-transition: 300ms ease-in-out;
    -o-transition: 300ms ease-in-out;
  }

  #all-stories-page .all-stories-content {
    flex: 1 1 100%;
    max-width: 100%;
    min-width: 100vw;
    transition: 300ms ease-in-out;
    -webkit-transition: 300ms ease-in-out;
    -moz-transition: 300ms ease-in-out;
    -ms-transition: 300ms ease-in-out;
    -o-transition: 300ms ease-in-out;
    height: 100%;
  }

  #all-stories-page .search-form {
    display: block;
  }

  .all-stories-page-main-container.__user-selected .all-stories-sider{
    height: 0!important;
    overflow-y: hidden;
  }

  .all-stories-page-main-container.__user-selected .all-stories-sider,
  .all-stories-page-main-container.__user-selected .all-stories-content {
    transform: translateX(-100vw);
    -webkit-transform: translateX(-100vw);
    -moz-transform: translateX(-100vw);
    -ms-transform: translateX(-100vw);
    -o-transform: translateX(-100vw);
  }

  .all-stories-page-main-container .all-stories-content {
    height: calc(100vh - 136px);
    height: calc(calc(1vh * 100) - 136px);
    height: calc(calc(var(--vh, 1vh) * 100) - 136px);
  }

  #all-stories-page .all-stories-sider .generic-card {
    box-shadow: unset!important;
    border: none;
    width: 100%;
    height: auto;
    padding: 0;
  }

  #all-stories-page .all-stories-sider .generic-card-content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  #all-stories-page .mobile-stories {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -12px 0 -12px;
  }

  #all-stories-page .mobile-stories .mobile-story-item-wrapper{
    flex: 0 0 25%;
    max-width: 25%;
    width: 25%;
    height: 234px;
    padding: 12px;
  }

  #all-stories-page .mobile-stories .stories-item {
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    padding: 10px;
    background-size: cover;
    background-position: center center;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper .stories-item {
    text-transform: unset;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper:not(:last-child) {
    margin-right: 40px;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper .stories-item .MuiButton-label {
    flex-wrap: wrap;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper .avatar-field {
    margin-right: 0;
    width: 78px!important;
    height: 78px!important;
    margin-bottom: 14px;
    border-width: 4px;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper .stories-item .texts {
    flex: 1 1 100%;
    max-width: 100%;
    text-align: center;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper .stories-item .texts strong {
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
    letter-spacing: 0;
    white-space: nowrap;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper .stories-item .texts small {
    display: none;
  }

  #all-stories-page .mobile-stories .stories-item .avatar-field {
    padding: 0;
  }

  #all-stories-page .story-viewer .story-wrapper .story-head {
    z-index: 10;
  }

  #all-stories-page .story-viewer .story-wrapper .slider-wrapper {
    width: 100%;
  }

  #all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-arrow {
    display: none!important;
  }

  #all-stories-page .active-stories-content-wrapper .story-viewer-container .story-viewer{
    box-shadow: unset!important;
  }

  #all-stories-page .active-stories-content-wrapper {
    box-shadow: unset!important;
    padding: 0;
  }

  #all-stories-page .story-viewer-container {
    margin:0;
    width: 100%;
    position: relative;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper {
    position: static;
    padding-top: 0;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-navs .story-bar {
    height: 8px;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-head {
    padding: 24px 28px 0 28px;
  }

  #all-stories-page .all-stories-content.__has-one-story .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-head {
    padding-top: 16px;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner {
    margin-top: 0px;
    position: relative;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner .close-button {
    position: absolute;
    right: 0;
    top: 50%;
    color: #b2b2b2;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    display: block;
}

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner .close-button svg {
    font-size: 28px;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-content {
    height: 100%;
    background-size: cover;
  }

  #all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-dots {
    top: -1px;
    padding: 0 28px;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .favorite-btn {
    display: none;
  }

  #all-stories-page .emoji-textarea-container .textarea {
    padding-right: 90px;
  }

  #all-stories-page .reply-wrapper .border-colored-text-field-wrapper {
    width: 70%;
    margin: 0 auto;
  }

  #all-stories-page .reply-wrapper .border-colored-text-field-wrapper .border-colored-text-field-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  #all-stories-page .story-viewer-container .reply-wrapper {
    z-index: 11;
  }
  
  #all-stories-page .story-viewer-container .reply-wrapper .border-colored-text-field-container .favorite-btn {
    display: block;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  #all-stories-page .emoji-textarea-container .textarea {
    border-color: white!important;
    background-color: transparent;
    color: white;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-owner h6 {
    color: #fff;
  }

  #all-stories-page .reply-wrapper .border-colored-text-field-icon .create-post-box-button svg {
    color: #fff!important;
  }

  #all-stories-page .story-viewer .story-wrapper .slider-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
  }
}

@media screen and (max-width: 767px) {
  #all-stories-page .all-stories-sider {
    padding: 28px 16px;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper .avatar-field {
    width: 44px!important;    
    height: 44px!important;
    margin-bottom: 6px;
    border-width: 2px;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper:not(:last-child) {
    margin-right: 30px;
  }

  #all-stories-page .all-stories-sider .story-item-wrapper .stories-item .texts strong {
    font-size: 12px;
  }

  #all-stories-page .mobile-stories {
    display: flex;
    flex-wrap: wrap;
    margin: 20px -10px 0 -10px;
  }

  #all-stories-page .mobile-stories .mobile-story-item-wrapper {
    flex:0 0 33.33%;
    max-width: 33.33%;
    width: 33.33%;
    padding: 5px 10px;
    height: 160px;
  }

  #all-stories-page .mobile-stories .mobile-story-item-wrapper .avatar-field{
    width: 26px!important;
    height: 26px!important;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-content {
    background-size: contain;
  }

  #all-stories-page .story-viewer-container .reply-wrapper {
    padding: 0 16px;
  }

  #all-stories-page .reply-wrapper .border-colored-text-field-wrapper {
    width: 100%;
  }

  #all-stories-page .story-viewer .story-wrapper .slider-wrapper {
    padding-top: 75px;
  }

  #all-stories-page .all-stories-content:not(.__has-one-story) .story-viewer .story-wrapper .slider-wrapper {
    padding-top: 100px;
  }

  #all-stories-page .story-viewer .story-wrapper .slider-wrapper .slick-dots {
    top: 20px;
    height: 8px;
    padding: 0 16px;
  }

  #all-stories-page .story-viewer-container .story-viewer-wrapper .story-viewer .story-wrapper .story-head {
    padding: 40px 16px 0 16px;
  }
}

/*
@media screen and (max-width: 480px) {
  #all-stories-page .mobile-stories .mobile-story-item-wrapper {
    flex:0 0 100%;
    max-width: 100%;
    width: 100%;
  }
}
*/
.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */

.slick-dots,.slick-next,.slick-prev{position:absolute;display:block;padding:0}.slick-dots li button:before,.slick-next:before,.slick-prev:before{font-family:slick;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.slick-loading .slick-next,.slick-prev{font-size:0;line-height:0;top:50%;width:20px;height:20px;transform:translate(0,-50%);cursor:pointer;color:transparent;border:none;outline:0;background:0 0}.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{color:transparent;outline:0;background:0 0}.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before{opacity:1}.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{opacity:.25}.slick-next:before,.slick-prev:before{font-size:20px;line-height:1;opacity:.75;color:#fff}.slick-prev{left:-25px}[dir=rtl] .slick-prev{right:-25px;left:auto}.slick-prev:before{content:'←'}.slick-next:before,[dir=rtl] .slick-prev:before{content:'→'}.slick-next{right:-25px}[dir=rtl] .slick-next{right:auto;left:-25px}[dir=rtl] .slick-next:before{content:'←'}.slick-dotted.slick-slider{margin-bottom:30px}.slick-dots{bottom:-25px;width:100%;margin:0;list-style:none;text-align:center}.slick-dots li{position:relative;display:inline-block;width:20px;height:20px;margin:0 5px;padding:0;cursor:pointer}.slick-dots li button{font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;color:transparent;border:0;outline:0;background:0 0}.slick-dots li button:focus,.slick-dots li button:hover{outline:0}.slick-dots li button:focus:before,.slick-dots li button:hover:before{opacity:1}.slick-dots li button:before{font-size:6px;line-height:20px;position:absolute;top:0;left:0;width:20px;height:20px;content:'•';text-align:center;opacity:.25;color:#000}.slick-dots li.slick-active button:before{opacity:.75;color:#000}/*# sourceMappingURL=slick-theme.min.css.map */
.portfolio-body{
    padding:40px 60px 60px 60px;
    max-width:1000px;
}

.portfolio-header{
    display:flex;
    align-items:center;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    padding-bottom:30px;
}

.portfolio-header .portfolio-image{
    height:182px;
    width:182px;
    margin-right:60px;
}

.verified-icon{
    height:18px;
    width:18px;
    margin-left:14px;
}

.portfolio-body h4,
.portfolio-body h3,
.portfolio-body p,
.portfolio-body span{
    font-family: OpenSans,sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin:0;
}

.portfolio-header h3{
    font-size: 46px;
    font-weight: 600;
    color:#3f526d;
    margin-bottom:2px;
}

.portfolio-header span:first-child{
    font-weight: 600;
    margin-bottom:15px;
}

.portfolio-header span:last-child,
.portfolio-header span:first-child{
    font-size: 24px;
    color:#3f526d;
    opacity:0.68;
}

.portfolio-information{
    display:flex;
    flex-direction:column;
    grid-gap:15px;
    gap:15px;
}

.portfolio-body h4{
    font-size: 20px;
    font-weight: 600;
    color:#3f526d;
    margin-top:36px;
}

.portfolio-body p{
    font-size: 20px;
    color:#3f526d;
    margin:0;
    padding:12px 26px 31px 0;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    line-height:1.6;
}

.portfolio-body .user-summary-items{
    display:flex;
    justify-content: space-between;
    align-items:center;
    padding:31px 0;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
    grid-gap:26px;
    gap:26px;
}

.portfolio-body .summary-item{
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 10px;
    border: solid 0.3px rgba(63, 82, 109, 0.28);
    background-color: #fff;
    width:25%;
    height:64px;
}

.portfolio-body .summary-item img{
    width:38px;
    height:38px;
    margin-right:16px;
    margin-left:5px;
}

.portfolio-body .summary-item-total,
.portfolio-body .summary-item-type{
    font-size:18px;
    color:#3f526d;
}

.portfolio-body .summary-item-total{
    font-weight:600;
}

.portfolio-body .summary-item-type{
    color:rgba(63, 82, 109, 0.5);
}

.portfolio-body .achievements-wrapper{
    display:flex;
    align-items:center;
    grid-gap:50px;
    gap:50px;
    padding-bottom:85px;
    border-bottom:1px solid rgba(63, 82, 109, 0.28);
}

.portfolio-body .achievement{
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-top:19px;
}

.portfolio-body .achievement-text{
    font-size: 16px;
    font-weight: 600;
    opacity:0.74;
    color:#3f526d;
}

.achievement-image{
    height:120px;
    margin-top:20px;
}

.portfolio-body .goal-items{
    display:flex;
    flex-direction:column;
    grid-gap:10px;
    gap:10px;
}

.portfolio-body .goal-wrapper{
    border-radius: 8px;
    border: solid 0.3px #979797;
    padding:12px 20px 12px 30px;
}

.portfolio-body .goal-wrapper:first-of-type{
    margin-top:20px;
}

.portfolio-body .goal-item{
    display:flex;
    align-items:center;
    justify-content: space-between;
}

.portfolio-body .goal-text{
    display:flex;
    align-items:center;
}

.portfolio-body .goal-text img{
    width:18px;
    height:18px;
    margin-right:18px;
}

.portfolio-body .goal-text span{
    font-size: 16px;
    font-weight: 600;
    color:#3f526d;
}

.portfolio-body .goal-item .due-date{
    border-radius: 6px;
    background-color: #f9f8fd;
    padding:8px 15px 7px 15px;
    display:inline-block;
    width: 200px;
    text-align: center;
}

.portfolio-body .goal-item .due-date span{
    font-family: OpenSans,sans-serif;
    font-size: 12px;
    text-align: center;
    color: #3f526d;
}

.portfolio-body .goal-item .due-date span:first-child{
    font-weight:600;
}

.portfolio-body .goal-completed{
    padding:8px 68px;
    border-radius:6px;
    background-color: #51ce32;
    color:#fff;
    font-size: 12px;
    font-weight: 600;
}

.portfolio-body .goal-picture-container{
    width:100%;
    display:flex;
    align-items:center;
}

.portfolio-body .goal-picture-container img{
    width:600px;
    margin:50px auto;
}

@media(max-width:1024px){
    .portfolio-body{
        padding:20px 40px 40px 40px;
    }
    .portfolio-header{
        padding-bottom:20px;
    }
    .portfolio-header .portfolio-image{
        width:62px;
        height:62px;
        margin-right:20px;
    }
    .portfolio-header h3{
        font-size:18px;
        font-weight:500;
    }
    .portfolio-header span:last-child, .portfolio-header span:first-child{
        font-size:14px;
    }
    .verified-icon{
        width:14px;
        height:14px;
    }
    .portfolio-information{
        grid-gap:6px;
        gap:6px;
    }
    .portfolio-body h4{
        margin-top:14px;
        font-size:16px;
        font-weight:500;
    }
    .portfolio-body p{
        font-size:14px;
        line-height:1.71;
        padding: 8px 26px 16px 0;
    }
    .portfolio-body .user-summary-items{
        padding:20px 0;
        grid-gap:20px;
        gap:20px;
    }
    .portfolio-body .summary-item img{
        width:18px;
        height:18px;
        margin-right:10px;
    }
    .portfolio-body .summary-item-total, .portfolio-body .summary-item-type{
        font-size:14px;
    }
    .portfolio-body .summary-item-total{
        font-weight:500;
    }
    .portfolio-body .summary-item{
        height:44px;
    }
    .portfolio-body .achievements-wrapper{
        padding-bottom:20px;
    }
    .portfolio-body .achievement{
        margin-top:23px;
    }
    .portfolio-body .achievement-text,
    .portfolio-body .goal-text span{
        font-size: 14px;
        font-weight: 500;
    }
    .achievement-image{
        height:100px;
    }
    .goal-items{
        margin-top:23px;
    }
    .portfolio-body .goal-wrapper{
        margin-top:0;
        padding:9px 10px 9px 20px;
    }
    .portfolio-body .goal-text img{
        margin-right:20px;
    }
    .portfolio-body .goal-picture-container img{
        padding:0 100px;
        margin:43px auto 16px auto;
    }
    .portfolio-body .goal-completed{
        font-weight:500;
    }
    .portfolio-body .goal-completed-container{
        margin-top:10px;
    }
}

@media(max-width:768px) {
    .portfolio-body {
        padding: 3px 17px 14px 17px;
    }

    .download-dialog-button {
        top: 14px;
        right: 17px;
        width: 26px;
        height: 26px;
    }

    .portfolio-header .portfolio-image {
        width: 38px;
        height: 38px;
        margin-right: 14px;
    }

    .download-dialog-button img {
        height: 14px;
    }

    .portfolio-body .summary-item img {
        margin: 0 0 2px 0;
    }

    .portfolio-header h3 {
        font-size: 12px;
    }

    .portfolio-header span:last-child, .portfolio-header span:first-child {
        font-size: 10px;
    }

    .verified-icon {
        width: 12px;
        height: 12px;
    }

    .portfolio-header {
        padding-bottom: 11px;
    }

    .portfolio-body h4 {
        font-size: 12px;
        margin-top: 9px;
    }

    .portfolio-body p {
        font-size: 12px;
        line-height: 1.5;
        padding: 10px 0;
    }

    .portfolio-body .summary-item {
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
    }

    .portfolio-body .summary-item-total, .portfolio-body .summary-item-type {
        font-size: 10px;
    }

    .portfolio-body .user-summary-items {
        grid-gap: 8.5px;
        gap: 8.5px;
        padding: 10px 0;
    }

    .portfolio-body .achievement {
        margin-top: 15px;
    }

    .portfolio-body .achievement-text {
        font-size: 10px;
    }

    .achievement-image {
        height: 60px;
        margin-top: 13px;
    }

    .portfolio-body .achievements-wrapper {
        padding-bottom: 14px;
        grid-row-gap: 10px;
        row-gap: 10px;
        grid-column-gap: 20px;
        -moz-column-gap: 20px;
             column-gap: 20px;
        flex-wrap: wrap;
    }

    .portfolio-body .goal-items {
        margin-top: 0;
    }

    .portfolio-body .goal-wrapper:first-of-type {
        margin-top: 10px;
    }

    .portfolio-body .goal-item {
        flex-direction: column;
        align-items:flex-start;
        grid-gap:10px;
        gap:10px;
    }
    .portfolio-body .goal-wrapper{
        padding:12px 10px 7px 10px;
    }
    .portfolio-body .goal-text img{
        width:16px;
        height:16px;
        margin-right:8px;
    }
    .portfolio-body .goal-text span{
        font-size:12px;
    }
    .portfolio-body .goal-completed{
        font-size:10px;
        padding: 8px 26px;
    }
    .portfolio-body .goal-picture-container img{
        width:100%;
        margin:26px 0 4px 0;
        padding:0 6px;
    }
}


.box-controller-container{
    display:flex;
    align-items:center;
    grid-gap:18px;
    gap:18px;
    margin-bottom:24px;
}

.box-controller-item{
    height:48px;
    width:48px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border-radius:8px;
    display:flex;
    align-items:center;
    justify-content: center;
    cursor:pointer;
}

.box-controller-item img{
    width:24px;
    height:24px;
}

.box-controller-item:last-child svg{
    font-size: 36px;
}

@media(max-width:1024px){
    .box-controller-container{
        display:none;
    }
}


/* Textarea */

#create-content-widget {
    margin-bottom: 24px;
}

#create-content-widget .widget-title{
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
    margin-bottom: 14px;
    }

#create-content-widget .widget-content .textarea {
}

#create-content-widget .widget-content .textarea textarea {
    font-size: 14px;
    font-weight: 600;
    color: #3f526d;
}

#create-content-widget .widget-content .textarea textarea::-moz-placeholder {
    font-size: 14px;
    font-weight: 600;
    color: rgba(63, 82, 109, 0.68);
}

#create-content-widget .widget-content .textarea textarea::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: rgba(63, 82, 109, 0.68);
}

#create-content-widget .widget-content .textarea .mui-textarea-wrapper{
    padding: 22px 23px;
    min-height: 86px;
    border-radius: 8px;
    margin-bottom: 40px;
    background-color: #f9f8fd;
}

#create-content-widget .widget-content#add-to-badge .textarea .mui-textarea-wrapper{
    min-height: 59px;
    margin-bottom: 30px;
}


#create-content-widget .widget-content .textarea .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 0;
}

#create-content-widget .widget-content .textarea .avatar {
    width: 42px;
    height: 42px;
    margin-right: 15px;
}


/* CREATE CONTENT DIALOG */
.create-content-dialog-wrapper {
    width: 640px;
    padding: 20px 26px;
    padding-top: 0;
    max-width: 100%;
}

.create-content-dialog-wrapper .dialog-title {
    font-size: 18px;
    color: #3f526d;
    font-weight: 600;
}

.create-content-dialog-wrapper .dialog-content {
}

.create-content-dialog-wrapper .dialog-content .personal-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    margin-bottom: 26px;
}

.create-content-dialog-wrapper .dialog-content .personal-info .avatar {
    margin-right: 14px;
}

.create-content-dialog-wrapper .dialog-content .personal-info strong {
    font-size: 16px;
    font-weight: 600;
    color: #3f526d;
}

.create-content-dialog-wrapper .dialog-content .textarea textarea {
    font-size: 16px;
    color: #3f526d;
}

.create-content-dialog-wrapper .dialog-content .textarea textarea::-moz-placeholder {
    color: rgba(63, 82, 109, 0.5);
}

.create-content-dialog-wrapper .dialog-content .textarea textarea::placeholder {
    color: rgba(63, 82, 109, 0.5);
}

.create-content-dialog-wrapper .dialog-content .post-content.__highlighted{
    border-width: 0.5px;
    border-style: solid;
    background-color: #f9f8fd;
    padding: 7px;
    border-radius: 8px;
    position: relative;
    margin-top: 40px;
}

.create-content-dialog-wrapper .dialog-content .post-content .description{
    color: #3f526d;
    line-height: 26px;
    font-size: 16px;
    letter-spacing: 0;
    padding: 6px;
}

.create-content-dialog-wrapper .dialog-content .post-content.__has-images .description{
    margin-top: 8px;
}

.create-content-dialog-wrapper .dialog-content .post-content.__has-files .description{
    margin-bottom: 8px;
}

.create-content-dialog-wrapper .dialog-content .post-content:not(.__has-description) .description{
    padding: 0;
}

.create-content-dialog-wrapper .dialog-content .image-list.__zero-item {
    padding: 0;
    border: 0;
}

.create-content-dialog-wrapper .dialog-content .image-list .image-item {
    padding: 6px;
    position: relative;
}

.create-content-dialog-wrapper .dialog-content .image-list.__one-item .image-item {
    width: 100%;
    height: 256px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__one-item .image-item img{
    -o-object-fit: contain!important;
       object-fit: contain!important;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items {
    display: grid;
    grid-template-columns: 50% 50%;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items .image-item {
    width: 100%;
    height: 256px;
}


.create-content-dialog-wrapper .dialog-content .image-list.__three-items {
    display: grid;
    grid-template-columns: 50% 50%;
}


.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(1) {
    grid-row: span 2;
    height: 256px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(2) {
    grid-row: span 1;
    height: 128px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__three-items .image-item:nth-child(3) {
    grid-row: span 1;
    height: 128px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__higher-than-three-items .images-count {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0;
    position: absolute;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.35);
    width: 100%;
    width: calc(100% - 12px);
    height: 100%;
    height: calc(100% - 12px);
    margin-left: 6px;
    margin-top: 6px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    pointer-events: none;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
}

.create-content-dialog-wrapper .dialog-content .image-list .image-item img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 8px;
}

.create-content-dialog-wrapper .dialog-content .image-list.__two-items .image-item {

}

.create-content-dialog-wrapper .dialog-content .closeButton {
    position: absolute;
    right: 5px;
    top: 5px;
    min-width: unset;
}

.create-content-dialog-wrapper .dialog-content .closeButton svg {
    color: #fff;
}

.create-content-dialog-wrapper #create-content-footer-section-wrapper {
    margin-top: 20px;
}

@media(max-width:1024px){
    #create-content-widget{
        padding:14px;
    }
    #create-content-widget .widget-content .textarea textarea,
    #create-content-widget .widget-title{
        font-size:12px;
        font-weight:500;
    }
    #create-content-widget .widget-title{
        margin-bottom:20px;
    }
    #create-content-widget .widget-content .textarea .avatar{
        width:34px;
        height:34px;
    }
    #create-content-widget .generic-card{
        padding:10px 14px;
    }
    #create-content-widget .widget-content .textarea .mui-textarea-wrapper{
        padding:12px;
        min-height:58px;
        margin-bottom:30px;
    }
    .create-content-dialog-wrapper .dialog-title{
        font-size:14px;
        font-weight:500;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info strong{
        font-size:14px;
        font-weight:500;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info .avatar{
        width:34px;
        height:34px;
        margin-right:10px;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info{
        margin-top:18px;
        margin-bottom:20px;
    }
    .create-content-dialog-wrapper .dialog-content .textarea textarea{
        font-size:14px;
    }
    .create-content-dialog-wrapper .dialog-content .post-content.__highlighted{
        margin:40px 50px;
    }
    .create-content-dialog-wrapper #create-content-footer-section-wrapper{
        margin:20px 50px 0 50px;
    }
    .create-content-dialog-wrapper .dialog-content .post-content .description{
        font-size:14px;
        line-height:1.86;
    }
    .share-to-feed-content .post-content.__highlighted{
        margin:40px 0;
    }
}

@media(max-width:768px){
    #create-content-widget{
        padding:10px;
        margin-bottom:10px;
    }
    #create-content-widget .widget-title{
        margin-bottom:10px;
    }
    .create-content-dialog-wrapper{
        padding:3px 20px 20px 20px;
    }
    #create-content-widget .widget-content .textarea .mui-textarea-wrapper{
        padding:8px 10px;
        margin-bottom:17px;
        min-height:50px;
    }
    #create-content-widget .widget-content .textarea .avatar{
        width:28px;
        height:28px;
        margin-right:0;
    }
    #create-content-widget .widget-content .textarea textarea::-moz-placeholder{
        font-size:10px;
    }
    #create-content-widget .widget-content .textarea textarea,
    #create-content-widget .widget-content .textarea textarea::placeholder,
    #create-content-widget .widget-title,
    #target-group-wrapper .target-group-selectbox .selectbox-wrapper,
    #create-content-footer-section-wrapper .right-side .action-button,
    #create-content-footer-section-wrapper .left-side .section-item{
        font-size:10px;
    }

    #create-content-widget .widget-content .textarea textarea{
        opacity:0.68;
    }
    #create-content-footer-section-wrapper .left-side .section-item img{
        width:16px;
        height:16px;
        margin-right:0;
    }
    #create-content-footer-section-wrapper .right-side .action-button{
        height:28px;
        width:60px;
        margin-left:12px;
    }
    .share-to-feed-content~#create-content-footer-section-wrapper .right-side .action-button{
        margin-left:6px;
    }
    #target-group-wrapper .target-group-selectbox.__small > .MuiSelect-select{
        padding:6px 20px 5px 10px;
    }
    #create-content-footer-section-wrapper{
        flex-wrap:nowrap;
    }
    .create-content-dialog-wrapper .dialog-title{
        font-size:12px;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info{
        margin-bottom:12px;
    }
    .create-content-dialog-wrapper.create-content-timeline .dialog-content .personal-info{
        margin-top:8px;
        margin-bottom:18px;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info .avatar{
        width:26px;
        height:26px;
    }
    .create-content-dialog-wrapper .dialog-content .personal-info strong{
        font-size:10px;
    }
    .create-content-dialog-wrapper .dialog-content .textarea textarea{
        font-size:12px;
        opacity:0.5;
    }
    .create-content-dialog-wrapper .dialog-content .post-content.__highlighted{
        margin:16px 0 20px 0;
    }
    .create-content-dialog-wrapper .dialog-content .post-content .description{
        font-size:12px;
        line-height:1.5;
    }
    .create-content-dialog-wrapper #create-content-footer-section-wrapper{
        margin:20px 0 0 0;
    }
    .share-to-feed-content  .image-list.__higher-than-three-items .image-item,
    .create-content-timeline  .image-list.__higher-than-three-items .image-item{
        height:150px!important;
        grid-row:span 1!important;
        padding:3px;
    }
    .create-content-dialog-wrapper .dialog-content .share-to-feed-content .image-list.__higher-than-three-items .images-count,
    .create-content-dialog-wrapper.create-content-timeline .dialog-content .share-to-feed-content .image-list.__higher-than-three-items .images-count{
        width:calc(100% - 6px);
        height:calc(100% - 6px);
        margin-left:3px;
        margin-top:3px;
    }
    .create-content-dialog-wrapper .dialog-content .closeButton {
        right: 3px;
        top: 3px;
        padding:3px 11px
    }

    .create-content-dialog-wrapper .dialog-content .closeButton svg {
        font-size:1.3rem;
    }
}

.single-dot{
    width: 2px;
    height: 2px;
    margin: 0 12px;
    background-color: #3f526d;
}

.single-post{
    margin-bottom:24px;
}

.boxed-view-container{
    max-width:33.33333333%;
    flex-basis:33.33333333%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0;
}

.list-view-container{
    max-width:100%;
    flex-basis:100%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0!important;
}

.badges-container{
    max-width:50%;
    flex-basis:50%;
    flex-grow:0;
    box-sizing:border-box;
    padding:11px;
    margin-bottom:0;
}

@media (max-width:1400px){
    .boxed-view-container{
        max-width:50%;
        flex-basis:50%;
    }
}

.boxed-view{
    /*min-width:270px;*/
}

.post-image{
    width: 34px;
    height: 34px;
    border-radius:50%;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right:10px;
}

.single-post-container{
    padding: 19px 30px 20px 30px;
}

.boxed-view .single-post-container{
    padding:20px 16px 14px 14px;
}

.single-post-container h4{
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.single-post-container span{
    color: #3f526d;
    font-size: 12px;
    opacity: 0.68;
}

.single-post-wrapper{
    position:relative;
}

.more-vertical{
    color:#3f526d;
    opacity:0.24;
    position:absolute!important;
    top:5px;
    right:0;
}

.post-description{
    margin-top:17px;
}

.post-description p{
    font-size: 16px;
    line-height: 1.63;
    margin-bottom:20px;
    color:#3f526d;
}

.boxed-view .post-description p.box-view-description{
    min-height:90px;
}

.boxed-view .post-description p.box-view-description,
.list-view .post-description p.box-view-description{
    font-size:14px;
    line-height:1.57;
    margin-top:20px;
    margin-bottom:18px;
}

.post-description p.goal-text{
    font-weight:600;
}

.post-description .percentage-bar-wrapper{
    display:flex;
    align-items:center;
    grid-gap:17px;
    gap:17px;
    height:9px;
    margin-top:23px;
    margin-bottom:20px;
}

.post-description .percentage-bar-wrapper .percentage-bar{
    width:270px;
    height:7.8px;
    background-color: #eef0f8;
    position:relative;
    border-radius: 3.5px;
}

.post-description .percentage-bar-wrapper .percentage-text{
    font-size:12px;
    font-weight:500;
    color:#3f526d;
    margin:0;
}

.post-description .percentage-bar-wrapper .percentage-progress{
    position:absolute;
    height:100%;
    border-radius: 3.5px;
}

.post-description .question-wrapper p{
    margin-top:16px;
}

.post-description .question-wrapper p,
.post-description .question-wrapper span{
    font-weight:600;
}

.post-description .question-wrapper span{
    font-size:14px;
    color:#3f526d;
    opacity:1;
}

.post-description .question-wrapper div{
    display:flex;
    align-items:center;
}

.post-description .question-wrapper div:first-child{
    margin-bottom:10px;
    margin-top:5px;
}

.post-description .question-wrapper span:first-child{
    margin-right:10px;
    color: rgba(63, 82, 109, 0.8);
}

.post-description p.video-text{
    margin-bottom:0;
}

.single-post-container hr{
    border-color:rgba(63, 82, 109, 0.12);
    border-width:1px;
}

.file-box{
    margin-bottom:20px!important;
}

.download-wrapper{
    text-decoration:none;
}

.file-box span{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
    margin-left:16px;
    opacity:1;
}

.boxed-view .file-box span,
.list-view .file-box span{
    margin-left:10px;
}

.file-box .file-icon{
    width:38px;
    height:38px;
}

.boxed-view .file-box .file-icon,
.list-view .file-box .file-icon{
    width:20px;
    height:20px;
}

.file-box .download-icon{
    width:18px;
    height:18px;
}

.boxed-view .file-box .download-icon,
.list-view .file-box .download-icon{
    width:12px;
    height:12px;
    margin-right:8px;
}

.post-pictures{
    margin-bottom:20px!important;
    display:flex;
    align-items:stretch;
    flex-wrap:wrap;
    grid-gap:12px;
    gap:12px;
}

.post-pictures .first-half, .post-pictures .second-half{
    max-height:256px;
    flex:1 1;
    width:100%;
    border-radius:8px;
    -o-object-fit:cover;
       object-fit:cover
}

.post-pictures .half-height{
    max-height:122px;
    width:100%;
    flex:1 1;
    border-radius:8px;
    -o-object-fit:cover;
       object-fit:cover
}

.post-pictures-second-half{
    display:flex;
    flex-direction: column;
    flex:1 1;
    grid-gap:12px;
    gap:12px;
}

.post-pictures .full-size{
    width:100%;
    max-height:256px;
}

.post-link{
    font-size: 16px!important;
    color: #3a60d7!important;
    margin-bottom:11px!important;
}

.goal-picture,.video-picture{
    width:100%;
}

.video-picture-container{
    position:relative;
    cursor:pointer;
    margin-bottom:20px;
    width:100%;
    aspect-ratio:16/9;
}

.video-picture{
    border-radius:8px;
    height:100%;
}

.video-icon{
    position:absolute;
    margin:auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:50px;
    height:50px;
}

.single-post-container .due-date{
    border-radius: 6px;
    background-color: #f9f8fd;
    padding:8px 15px 7px 15px;
    margin-bottom:22px;
    display:inline-block;
}

.single-post-container .due-date span{
    font-size: 12px;
    text-align: center;
    color: #3f526d;
}

.single-post-container .due-date span:first-child{
    font-weight:600;
}

.menu-list img{
    margin-right:22px;
    width:20px;
}

.menu-list li{
    font-size: 16px;
    color:#3f526d;
}

.boxed-view{
    /*max-width:33.33%;*/
    /*flex:0 0 33%;*/
    width:100%;
}

.boxed-view span.file-text,
.list-view span.file-text{
    font-size:14px;
}

.badges-container .goal-picture{
    max-height:160px;
    -o-object-fit:contain;
       object-fit:contain;
    margin-bottom:30px;
    margin-top:20px;
}

.questions-container p:first-child{
    font-size:14px;
}

.questions-container .answer-text{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109, 0.6);
}

.questions-container .answer-text:hover{
    text-decoration:underline;
    cursor:pointer;
}

.questions-container .image-item,.questions-container .image-item img{
    height:unset!important;
}

.questions-container .image-list .image-item{
    padding:0!important;
}

.single-post-container .comments{
    display:flex;
    flex-direction:column;
    margin-top:20px;
}

.single-post-container .load-more-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    text-transform:unset!important;
    margin-top:20px;
    max-width:200px;
    margin-left:20px;
}

.single-post-container .load-more-button .MuiButton-label{
    opacity:1!important;
}

.single-post-container .load-more-button img{
    width:8px;
    height:16px;
}

.replies .comment-container{
    background-color:#fff;
}

.single-post-container .image-list .image-item {
    padding: 6px;
    position: relative;
}

.single-post-container .image-list.__one-item .image-item {
    width: 100%;
    height: 256px;
}

.single-post-container .image-list.__one-item .image-item img{
    -o-object-fit: contain!important;
       object-fit: contain!important;
}

.single-post-container .image-list.__two-items {
    display: grid;
    grid-template-columns: 50% 50%;
}

.single-post-container .image-list.__two-items .image-item {
    width: 100%;
    height: 256px;
}


.single-post-container .image-list.__three-items {
    display: grid;
    grid-template-columns: 50% 50%;
}


.single-post-container .image-list.__three-items .image-item:nth-child(1) {
    grid-row: span 2;
    height: 256px;
}

.single-post-container .image-list.__three-items .image-item:nth-child(2) {
    grid-row: span 1;
    height: 128px;
}

.single-post-container .image-list.__three-items .image-item:nth-child(3) {
    grid-row: span 1;
    height: 128px;
}

.single-post-container .images-count span {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    font-size: 20px;
    letter-spacing: 0;
    position: absolute;
    left:0;
    top:0;
    background-color: rgba(0,0,0,0.35);
    width: 100%;
    width: calc(100% - 12px);
    height: 100%;
    height: calc(100% - 12px);
    margin-left: 6px;
    margin-top: 6px;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
    pointer-events: none;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
}

.single-post-container .image-item img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 8px;
}

@media(max-width:1024px){
    .single-post-container h4,
    .file-box span{
        font-size:14px;
        font-weight:500;
    }
    .post-image{
        width:40px;
        height:40px;
    }
    .post-description p{
        font-size:14px;
    }
    .post-link{
        font-size:14px!important;
    }
    .file-box .file-icon{
        width:34px;
        height:34px;
    }
    .badges-container .single-post-container{
        padding: 19px 14px 20px 14px;
    }
    .badges-container .post-interactions span{
        font-size:12px;
        white-space:nowrap;
        margin-left:8px;
    }
    .badges-container .post-interactions img{
        width:16px;
        height:16px;
    }
    .boxed-view-container{
        max-width: 100%;
        flex-basis: 100%;
    }
    .boxed-view .post-description p.box-view-description{
        min-height:unset;
        line-height:1.86;
    }
    .file-box .download-icon{
        width:18px;
        height:18px;
    }
    .boxed-view .single-post-container{
        padding:20px 28px;
    }
    .post-description .question-wrapper span,
    .post-description .question-wrapper p{
        font-weight:500;
    }
    .questions-container .answer-text{
        font-size:14px;
        font-weight:500;
    }
    .post-description .question-wrapper p{
        font-size:16px;
    }
    .answers-dialog-question-container .MuiPaper-root,
    .answers-dialog-container .MuiPaper-root{
        box-shadow:none;
    }
    .answers-dialog-question-container .more-vertical,
    .answers-dialog-container .more-vertical,
    .answers-dialog-container .first-line{
        display:none;
    }
    .answers-dialog-question-container .list-view-container{
        padding-top:0;
    }
    .answers-dialog-question-container .single-post-container,
    .answers-dialog-container .single-post-container{
        padding:0 17px 9px 17px;
    }
    .answers-dialog-question-container .post-description .question-wrapper span,
    .answers-dialog-question-container .post-description .question-wrapper p{
        font-weight:600;
    }
    .answers-dialog-question-container .post-description .question-wrapper span{
        font-size:14px;
    }
    .answers-dialog-container .post-description p{
        font-size:12px;
        line-height:normal;
    }
    .answers-dialog-container .first-line{
        margin-bottom:16px;
    }
    .answers-dialog-container .second-line{
        margin-top:16px;
    }
    .answers-dialog-question-container .post-description .question-wrapper p{
        line-height:1.38;
    }
    .answers-dialog-container .post-image{
        width:30px;
        height:30px;
    }
    .answers-dialog-container .list-view-container{
        padding-top:0;
        padding-bottom:0;
    }
    .answers-dialog-question-container .questions-container .answer-text{
        display:none;
    }
    .menu-list li{
        font-size:14px;
    }
    .menu-list img{
        width:18px;
        height:18px;
        margin-right:10px;
    }
    .badges-container .goal-picture{
        max-height:174px;
    }
    .badges-container .post-interactions{
        grid-gap:20px;
        gap:20px;
    }
}

@media(max-width:768px){
    .single-post{
        margin-bottom:10px;
    }
    .single-post-container,
    .boxed-view .single-post-container{
        padding:10px 10px 16px 10px;
    }
    .answers-dialog-container .single-post-container{
        padding:0 10px;
    }
    .answers-dialog-container .post-description p{
        margin-bottom:14px;
    }
    .post-image{
        width:32px;
        height:32px;
    }
    .single-post-container span{
        font-size:10px!important;
    }
    .single-post-container h4,
    .file-box span,
    .post-description p,
    .post-link{
        font-size:12px!important;
    }
    .post-interactions span{
        margin-left:8px!important;
    }
    .post-interactions{
        grid-gap:24px!important;
        gap:24px!important;
    }
    .post-interactions svg{
        font-size:1rem;
    }
    .post-interactions img{
        width:16px!important;
        height:16px!important;
    }
    .more-vertical .MuiSvgIcon-root{
        font-size:1rem;
    }
    .file-box .file-icon{
        width:28px;
        height:28px;
    }
    .file-box span{
        margin-left:12px;
    }
    .file-box .download-icon{
        width:16px!important;
        height:16px!important;
    }
    .single-post-container .due-date span{
        opacity:1;
    }
    .single-post-container .due-date{
        width:200px;
        margin-bottom:11px;
        white-space: nowrap;
        text-align: center;
    }
    .single-post-container .goal-picture{
        padding:0 24px;
        margin-bottom:15px;
    }
    .boxed-view-container{
        margin-bottom:0!important;
        padding:8px 8px 0 8px;
    }
    .badges-container{
        max-width:100%;
        flex-basis:100%;
        padding:10px 8px 0 8px;
        margin-bottom: 0;
    }
    .badges-container .post-description p.goal-text{
        margin:18px 0;
        font-weight:500;
    }
    .badges-container .goal-picture{
        max-height:150px;
        margin-bottom:30px;
    }
    .post-description .question-wrapper span{
        font-size:12px!important;
    }
    .questions-container .answer-text{
        font-weight:600;
        color:rgba(63, 82, 109, 0.8);
        margin-top:16px;
        margin-bottom:12px;
    }
    .answers-dialog-question-container .post-description .question-wrapper span,
    .answers-dialog-question-container .post-description .question-wrapper p{
        font-weight:500;
    }
    .answers-dialog-question-container .questions-container .image-item img{
        margin-bottom:20px;
    }
    .answers-dialog-question-container .list-view-container{
        padding-bottom:0;
    }
    .answers-dialog-container .post-image{
        width:26px;
        height:26px;
        margin-right:16px;
    }
    .answers-dialog-container .second-line{
        margin-bottom:10px;
    }
    .comment-box img{
        width:22px;
        height:22px;
    }
    .video-icon{
        width:30px;
        height:30px;
    }
    .single-post-container .image-list.__higher-than-three-items .image-item{
        height:150px!important;
        grid-row:span 1!important;
    }
    .questions-container{
        padding:5px 8px;
    }
}

.create-post-box{
    grid-gap:14px;
    gap:14px;
    margin-top:20px!important;
    display:flex;
    align-items:center;
}

.create-post-box img{
    width:34px;
    height:34px;
}

.create-post-box form{
    display:flex;
    width:100%;
}

.create-post-box input{
    flex:1 1;
    width:100%;
    font-weight:600;
    padding: 13px 90px 13px 20px;
    position:relative;
    background-color:#f9f8fd;
    border-radius: 6px;
    border:none;
    min-height:18px;
}

.create-post-box input::-moz-placeholder {
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input::placeholder {
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box input::-ms-input-placeholder { /* Microsoft Edge */
    color:rgba(63, 82, 109, 0.2);
}

.create-post-box-field{
    display:flex;
    align-items:center;
    width:100%;
    position:relative;
}

.create-post-box-field form{
    margin:0
}

.create-post-box-buttons{
    position:absolute;
    right:22px;
    display:flex;
    align-items:center;
    grid-gap:8px;
    gap:8px;
}

.create-post-box-buttons img{
    height:18px;
}

.create-post-box-button{
    height:20px;
    width:20px;
}

@media(max-width:1024px){
    .create-post-box input{
        font-size:12px;
        font-weight:500;
    }
    .create-post-box img{
        width:30px;
        height:30px;
    }
    .create-post-box .create-post-box-button img{
        width:18px;
        height:18px;
    }
}

@media(max-width:768px){
    .create-post-box{
        margin-top:10px!important;
        grid-gap:10px;
        gap:10px;
    }
    .create-post-box input{
        padding:10px 90px 10px 10px;
        font-size:10px;
    }
    .create-post-box .create-post-box-button img{
        width:16px;
        height:16px;
    }
    .create-post-box-buttons{
        right:15px;
    }
}

.post-interactions{
    grid-gap:30px;
    gap:30px;
}

.boxed-view .post-interactions{
    grid-gap:20px;
    gap:20px;
}

.post-interactions div{
    display:flex;
    align-items:center;
    cursor:pointer;
}

.post-interactions span{
    margin-left:12px;
    font-size:14px;
    color:#3f526d;
}

.boxed-view .post-interactions span:not(.MuiIconButton-label){
    margin-left:8px;
    font-size:12px;
}

.post-interactions img{
    height:18px;
    width:18px;
}

.share-button{
    padding:0!important;
}

.boxed-view .post-interactions svg{
    width:20px;
    height:20px;
}

.share-button:hover{
    background-color:transparent!important;
    text-align:left!important;
}

.share-button .MuiIconButton-label{
    margin:0!important;
}

.comments-dialog-body{
    display:flex;
    flex-direction:column;
    margin:1px 6px;
    min-width:300px;
    padding:8px 24px
}

.comments-dialog-title{
    font-size: 18px;
    font-weight: 600;
    color:#3f526d;
    margin:0 0 30px 0;
}

.comment-container{
    display:flex;
    background-color:#f9f8fd;
    border-radius:10px;
    margin-left:18px;
    padding:8px 10px;
}

.comment-container p{
    font-size: 10px;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.comment-text-container{
    /*margin-left:18px;*/
}

.comments-dialog-create-box-container{
    padding:0 34px 27px 30px;
}

.comment-box-divider{
    margin:0;
    height:1px;
    background-color:rgba(0, 0, 0, 0.46);
    width:100%;
    opacity:0.1;
}

@media(max-width:1024px){
    .comments-dialog-body{
        padding:0 28px 4px 14px;
    }
    .comments-dialog-title{
        font-size:16px;
    }
    .comments-dialog-body .comment-box img{
        width:30px;
        height:30px;
    }
    .comments-dialog-body .comment-box{
        width:100%;
    }
    .comments-dialog-body .comment-container{
        border-radius:6px;
        width:100%;
        margin-left:22px;
    }
    .comments-dialog-body .comment-box h5{
        font-size:14px!important;
        margin:0 0 4px 0!important;
    }
    .comments-dialog-body .comment-container p{
        font-size:14px;
    }
    .comments-dialog-create-box-container{
        padding:0 34px 20px 20px;
    }
    .comments-dialog-create-box-container .create-post-box{
        grid-gap:22px;
        gap:22px;
    }
    .comments-dialog-create-box-container .create-post-box input{
        padding: 10px 90px 10px 20px;
    }
}

@media(max-width:768px){
    .comment-container{
        margin-left:14px;
        padding:4px 16px 7px 6px;
        border-radius:4px;
        width:100%;
    }
    .single-post-container .comments{
        margin-top:10px;
    }
    .comment-box h5{
        font-size:12px!important;
        font-weight:500!important;
    }
    .single-post-container .load-more-button span{
        font-weight:600;
    }
    .single-post-container .load-more-button{
        margin-top:10px;
        margin-left:35px;
    }
    .comments-dialog-title{
        font-size:12px;
        font-weight:500;
        margin:0 0 20px 0;
    }
    .comments-dialog-body .comment-box img{
        width:26px;
        height:26px;
    }
    .comments-dialog-body .comment-container{
        margin-left:14px;
    }
    .comments-dialog-body .comment-box h5,
    .comments-dialog-body .comment-container p{
        font-size:10px!important;
    }
    .MuiDialogContent-root:first-child{
        padding-top:13px!important;
    }
    .comments-dialog-body{
        padding:0 4px 4px 4px;
    }
    .comments-dialog-create-box-container{
        padding:0 10px 20px 10px;
    }
    .comments-dialog-create-box-container .create-post-box{
        grid-gap:10px;
        gap:10px;
    }
    .questions-container .comment-container{
        margin-left:4px;
    }
}

.comment-box{
    display:inline-flex;
    margin-bottom:10px;
    width:100%;
}

.comment-box img{
    width:26px;
    height:26px;
    border-radius:50%;
}

.comment-box h5{
    font-size: 10px;
    line-height: normal;
    color: #3f526d;
    font-weight: 600;
    margin: 0 0 2px 0;
}

.add-answer-dialog{
    padding:4px 24px 24px 24px;
    width:600px;
}

.add-answer-dialog-header{
    display:flex;
    align-items:center;
}

.add-answer-dialog-header img{
    width:34px;
    height:34px;
    border-radius:50%;
}

.add-answer-dialog-header h3{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    margin-left:12px;
}

.add-answer-dialog-body p{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #3f526d;
    margin:20px 0 14px 0;
}

.add-answer-dialog textarea{
    border:none;
    resize:none;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: rgba(63, 82, 109, 0.36);
    width:100%;
}

.add-answer-dialog textarea::-moz-placeholder {
    color:rgba(63, 82, 109, 0.36);
}

.add-answer-dialog textarea::placeholder {
    color:rgba(63, 82, 109, 0.36);
}

.add-answer-dialog textarea:focus{
    outline:none;
}

.add-answer-actions{
    display:flex;
    justify-content:flex-end;
    align-items:center;
    grid-gap:8px;
    gap:8px;
    position:relative;
}

.add-answer-actions img{
    width:20px;
    height:20px;
}

.add-answer-dialog .vertical-line{
    height:22.5px;
    width:1px;
    background-color:rgba(63, 82, 109, 0.12);
}

.add-answer-button{
    font-size: 16px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    text-transform:capitalize!important;
    width:100px;
}

.add-answer-dialog .image-list.__one-item .image-item{
    width: 100%;
    height: 256px;
}
.add-answer-dialog .image-list .image-item {
    padding: 6px;
    position: relative;
    margin-bottom:20px;
}
.add-answer-dialog .image-list .image-item img{
    width: 100%;
    height: 100%;
    cursor: pointer;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 8px;
}
.add-answer-dialog .image-list.__one-item .image-item img{
    -o-object-fit: contain!important;
       object-fit: contain!important;
}

.add-answer-dialog .file-list-item{
    margin-bottom:20px;
}

@media(max-width:1024px){
    .add-answer-dialog{
        width:88vw;
    }
    .add-answer-dialog-header h3{
        font-size:12px;
        font-weight:500;
    }
    .add-answer-dialog-header img{
        width:30px;
        height:30px;
    }
    .add-answer-dialog .file-list-item .file-name{
        font-size:14px;
        font-weight:500;
    }
    .add-answer-dialog .file-list-item .file-upload-time{
        font-size:12px;
    }
}

@media(max-width:768px){
    .add-answer-dialog{
        padding:0 20px 10px 10px;
        width:unset;
    }
    .add-answer-dialog-header h3{
        font-size:10px;
    }
    .add-answer-dialog-header img{
        width:26px;
        height:26px;
    }
    .add-answer-dialog-body p,
    .add-answer-dialog textarea{
        font-size:12px;
        font-weight:500;
    }
    .add-answer-dialog-body p{
        margin:14px 0;
    }
    .add-answer-actions img{
        width:18px;
        height:18px;
    }
    .add-answer-actions{
        grid-gap:0;
        gap:0;
    }
    .add-answer-button{
        margin-left:8px;
        font-size:12px!important;
        font-weight:500!important;
        width:84px;
        height:32px;
    }
    .add-answer-dialog .file-list-item .file-name{
        font-size:12px;
        margin-left:8px;
    }
    .add-answer-dialog .file-list-item .file-upload-time{
        font-size:10px;
    }
    .add-answer-dialog .file-list-item .file-icon{
        width:26px;
        height:26px;
    }
}

.send-in-chat-body{
    max-width:640px;
}

.send-in-chat-header,.send-in-chat-recent{
    padding:0 26px 21px 26px;
}

.send-in-chat-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.send-in-chat-body .post-information{
    background-color:#f9f8fd;
    border-radius:8px;
}

.send-in-chat-post-box{
    display:flex;
    align-items:center;
    margin-top:20px;
}

.send-in-chat-post-box img{
    width:42px;
    height:42px;
}

.send-in-chat-post-box input{
    padding:10px 16px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    opacity: 0.5;
    border:none;
    outline:none;
    width:100%;
}

.send-in-chat-divider{
    height: 1px;
    width:100%;
    background-color: rgba(63, 82, 109, 0.28);
}

.search-bar{
    border-radius: 22px;
    border-width: 1px;
    border-style: solid;
    padding-left:20px;
    padding-right:8px;
    display:flex;
    align-items:center;
    /*margin:19px 26px 0 26px;*/
    margin-top:19px;
    height: 44px;
}

.search-input{
    border:none;
    outline:none;
    height:100%;
    width:100%;
    padding-top:12px;
    padding-bottom:12px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#3f526d;
    opacity:0.4;
}

.search-button .search-icon{
    font-size: 27px;
}

.recent-title{
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.recent-activity-item{
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin-bottom:18px;
}

.recent-information{
    display:flex;
    align-items:center;
}

.recent-information h3{
    margin-left:14px;
}

.send-recent-button{
    height:38px;
    text-transform:capitalize!important;
    padding:19px 33px!important;
    box-shadow:none!important;
    font-size: 14px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    opacity: 0.7;
}

.send-recent-button:hover {
    opacity: 1;
}

@media(max-width:1024px){
    .send-in-chat-title{
        font-size:14px;
        font-weight:500;
        margin-top:0;
        margin-bottom:27px;
    }
    .send-in-chat-body .single-post-container{
        padding:24px 30px 20px 20px;
    }
    .send-in-chat-body .post-image{
        width:34px;
        height:34px;
    }
    .send-in-chat-body .post-description p{
        line-height:1.86;
        margin-bottom:0;
    }
    .send-in-chat-post-box input{
        font-size:14px;
    }
    .send-in-chat-post-box img{
        width:34px;
        height:34px;
    }
    .send-in-chat-post-box{
        margin:20px 20px 10px 20px;
    }
    .send-in-chat-recent .search-input{
        font-size:12px;
    }
    .send-in-chat-recent .recent-title{
        margin-top:24px;
        margin-bottom:27px;
        margin-left:0;
        font-size:14px;
        font-weight:500;
    }
    .send-in-chat-recent .avatar-field{
        width:44px!important;
        height:44px!important;
    }
    .send-recent-button{
        font-weight:500;
    }
    .recent-activity-item .recent-title{
        margin:0;
    }
}

@media(max-width:768px){
    .send-in-chat-title{
        margin-bottom:9px;
    }
    .send-in-chat-header, .send-in-chat-recent{
        padding:0 12px;
    }
    .send-in-chat-body .single-post-container{
        padding:8px 10px;
    }
    .send-in-chat-body .post-image{
        width:26px;
        height:26px;
    }
    .send-in-chat-body .send-in-chat-header h4{
        font-size:10px;
    }
    .send-in-chat-body .send-in-chat-header .post-description{
        margin-top:12px;
    }
    .send-in-chat-post-box{
        margin:10px 0;
    }
    .send-in-chat-post-box img{
        width:26px;
        height:26px;
    }
    .send-in-chat-post-box input{
        font-size: 10px;
    }
    .send-in-chat-header{
        padding:0 12px;
    }
    .send-in-chat-recent{
        padding:0 22px;
    }
    .send-in-chat-recent .search-input{
        padding-top:9px;
        padding-bottom:9px;
        font-size:10px;
        opacity:0.6;
    }
    .send-in-chat-recent .search-button .search-icon{
        font-size:18px;
    }
    .send-in-chat-recent .recent-title{
        margin-top:14px;
        margin-bottom:10px;
        font-size:12px;
    }
    .send-in-chat-recent .search-bar{
        height:32px;
    }
    .send-in-chat-recent .avatar-field{
        width:37px!important;
        height:37px!important;
        margin-right:10px;
    }
    .send-recent-button{
        font-size:12px!important;
        font-weight:500!important;
        width:84px;
        height:32px;
        padding:7px 28px;
    }
    .recent-activity-item{
        margin-bottom: 15px;
    }
}


.story-item{
    position:relative;
}

.story-item .main-image{
    width: 100px;
    height: 100px;
    border-radius: 8px;
    -o-object-fit: cover;
       object-fit: cover;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
}

.stories-images-container{
    display:flex;
    grid-gap:8px;
    gap:8px;
}

@media(max-width:1400px){
    .story-item .main-image{
        width:80px;
        height:80px;
    }
}

.story-item .mini-image{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    -o-object-fit: contain;
       object-fit: contain;
    position:absolute;
    top:6px;
    left:6px;
    border-width: 2px;
    border-style: solid;
}

.stories-header h3{
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    color: #3f526d;
    margin:0;
}

.stories-header button{
    font-size: 14px;
    font-weight:600;
    text-decoration: underline;
    text-transform: none;
}

.stories-container{
    margin-bottom:24px;
    margin-top:15px;
    grid-gap:30px;
    gap:30px;
    flex-wrap:nowrap!important;
}

/* CREATE STORY ITEM */
.create-story-button {
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    width: 100px;
    height: 100px;
    border-radius: 8px!important;
    text-transform: none;
}

@media(max-width:1400px){
    .create-story-button{
        width:80px;
        height:80px;
    }
}

.create-story-button > .MuiButton-label {
    flex-wrap: wrap;
}

.create-story-button svg {
    font-size: 32px;
}

.create-story-button .add-to-story-text{
    display: block;
    font-size: 12px;
    font-weight: 600;
    flex: 1 1 100%;
    width: 100%;
    max-width: 100%;
    text-align: center;
    color: #3f526d;
    margin-top: 10px;
    text-transform: none;
}

/* Share Story Modal */

.share-story-wrapper {
    min-width: 320px;
    min-height: 200px;
    padding: 30px 50px;
    width: 70vw;
}

.share-story-wrapper .target-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.share-story-wrapper .target-group .target-group-selectbox{
    width: 140px;
}

.share-story-wrapper .story-images-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
}

.share-story-wrapper .story-images-wrapper .slider-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.share-story-wrapper .story-images-wrapper .slick-slide {
    padding: 0 11px;
}

.share-story-wrapper .story-images-wrapper .carousel-item {
    position: relative;
    background-color: rgba(208, 194, 163, 0.2);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 500px;
    max-height: 100%;
}

.share-story-wrapper .story-images-wrapper .carousel-item .remove-button {
    position: absolute;
    right: 0;
    top: 0;
}

.share-story-wrapper .story-images-wrapper .slick-list {
    margin: 0 -20px;
    width: 100%;
}

.share-story-wrapper .story-images-wrapper .slick-prev {
    top: unset;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
}

.share-story-wrapper .story-images-wrapper .slick-arrow {
    width: 56px;
    height: 56px;
}

.share-story-wrapper .story-images-wrapper .slick-arrow:before {
    content: ''!important;
    display: none;
}

.share-story-wrapper .story-images-wrapper .slick-arrow svg {
    font-size: 32px;
}

.share-story-wrapper .story-images-wrapper .slick-arrow:not(.slick-disabled) svg {
    color: #676767;
}

.share-story-wrapper .story-images-wrapper .slick-arrow.slick-disabled svg {
    color: #d3d3d3;
}

.share-story-wrapper .story-images-wrapper .next-arrow-icon {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}

.share-story-wrapper .actions {
    display: flex;
    align-items: center;
    justify-content: center;
}

.share-story-wrapper .actions > button {
    text-transform: unset;
}

.share-story-wrapper .actions > button:first-child {
    margin-right: 10px;
    color: #3f526d;
    width: 170px;
    flex: 0 0 170px;
    max-width: 170px;
    background-color: #f9f8fd;
}

.share-story-wrapper .actions > button:last-child {
    margin-left: 10px;
    width: 200px;
    flex: 0 0 200px;
    max-width: 200px;
}

@media(max-width:1024px){
    .create-story-button{
        width:100px;
        height:100px;
    }
    .create-story-button .add-to-story-text{
        font-weight:500;
    }
    .story-item .main-image{
        width:100px;
        height:100px;
    }
    .stories-header{
        display:none!important;
    }
    .stories-container{
        margin-top:0;
        grid-gap:16px;
        gap:16px;
    }
    .share-story-wrapper .actions > button{
        font-size:14px!important;
        font-weight:500!important;
        height:48px;
    }
    .share-story-wrapper .story-images-wrapper{
        margin-bottom:65px;
    }
}

@media(max-width:768px){
    .create-story-button,
    .story-item .main-image{
        height:160px;
    }
    .stories-header{
        display:flex!important;
        margin-bottom:12px!important;
    }
    .stories-container{
        margin-bottom:10px;
        grid-gap:8px;
        gap:8px;
    }
    .story-item .mini-image{
        top:10px;
        left:10px;
    }
    .stories-header h3,
    .stories-header button{
        font-size:12px!important;
        font-weight:500!important;
    }
    .share-story-wrapper .actions > button{
        font-size:12px!important;
        height:28px;
    }
    .share-story-wrapper .actions > button:first-child{
        width:84px;
        flex: 0 0 84px;
        max-width: 84px;
    }
    .share-story-wrapper .actions > button:last-child{
        width:120px;
        flex: 0 0 120px;
        max-width: 120px;
    }
    .share-story-wrapper{
        padding:0 22px 22px 22px;
    }
    .share-story-wrapper .story-images-wrapper{
        margin-bottom:20px;
    }
    .share-story-wrapper .story-images-wrapper .slick-list{
        flex:1 1;
    }
    .stories-slider-container .stories-slider-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
    .stories-slider-container .stories-slider-wrapper .slick-list {
        width: 100%;
    }
    .stories-slider-container .stories-slider-wrapper .story-item {
        height:160px;
        max-height: 100%;
    }
    .stories-slider-container{
        overflow:hidden;
        margin-right: -16px;
    }
}

#create-goal-widget-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 35px;
}

#create-goal-widget-wrapper > div:last-child {
    margin-left: 18px;
}

#create-goal-widget-wrapper > div label {
    display: block;
    color: #3f526d;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: -20px;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root {
    margin: 0;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input {
    font-size: 12px;
    color: #3f526d;
    padding-top: 0;
    padding-bottom: 0;
    height: 38px;
    border: 0;
    border-radius: 6px;
    padding-right: 9px;
    width: 122px;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root fieldset {
    border: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    transition: 300ms ease;
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input:hover ~ fieldset {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

#create-goal-widget-wrapper > div label + .MuiTextField-root input:focus ~ fieldset {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

@media(max-width:768px){
    #create-goal-widget-wrapper{
        margin-top:20px;
        margin-bottom:30px;
    }
    #create-goal-widget-wrapper > div label + .MuiTextField-root{
        width:140px;
        height:30px;
    }
    #create-goal-widget-wrapper .MuiInputBase-adornedEnd{
        padding-right:10px;
    }
    #create-goal-widget-wrapper .MuiInputBase-adornedEnd button{
        padding:0;
    }
    #create-goal-widget-wrapper > div:last-child{
        margin-left: 12px;
    }
    #create-goal-widget-wrapper > div label + .MuiTextField-root input{
        font-size:10px;
        padding-left:10px;
    }
    #create-content-widget .MuiButton-label{
        white-space:nowrap;
    }
}

.add-question-box-wrapper{
    padding:11px;
    width:100%;
}

.add-question-box-title{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
    white-space: nowrap;
}

.add-question-box-filters{
    display:flex;
    align-items:center;
    grid-gap:10px;
    gap:10px;
    margin-bottom:17px;
}

.add-question-select-box{
    width:200px;
    font-size: 14px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

._add-question-select-box{
    font-size: 14px!important;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.add-question-select-box fieldset{
    border:none;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius:8px!important;
}

.add-question-select-box .MuiSelect-select{
    border-radius:8px!important;
    padding:15px 20px;
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
}

.add-question-select-box .MuiInputLabel-outlined{
    transform: translate(15px, 15px) scale(1)!important;
}

.add-question-select-box .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

.add-question-box-title-input,
.add-question-box-question-input{
    padding: 10px 20px!important;
    border-radius: 6px;
    background-color: #f9f8fd;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color:#3f526d;
    width:100%;
}

.add-question-box-question-input{
    margin-bottom:17px;
}

.add-question-box-footer{
    display:flex;
    justify-content:space-between;
}

.add-question-box-footer .add-question-box-buttons{
    display:flex;
    grid-gap:14px;
    gap:14px;
}

.add-question-box-footer .cancel-button,
.add-question-box-footer .add-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    box-shadow:none;
    width:84px;
    height:38px;
    border-radius:4px!important;
}

.add-question-box-footer .cancel-button{
    background-color: #f6f6f6;
    color:#3f526d;
}

.add-question-box-wrapper .target-group-selectbox {
    background-color: rgba(0,0,0,0.05)!important;
    height:38px;
    overflow:hidden;
}

@media(max-width:1024px){
    .add-question-box-footer .cancel-button, .add-question-box-footer .add-button{
        width:120px;
        font-weight:500;
    }
    .add-question-select-box .MuiInputLabel-outlined{
        transform: translate(15px, 13px) scale(1)!important;
        font-size:12px;
    }
    .add-question-select-box .MuiOutlinedInput-root{
        height:38px;
    }
    ._add-question-select-box{
        font-weight:500!important;
    }
    .add-question-box-title-input input, .add-question-box-question-input input{
        font-size:14px;
        font-weight:500;
        padding:0.68px 0;
    }
    .add-question-box-title{
        font-weight:500;
    }
    #menu-topic li{
        font-size:14px;
        padding-left:10px;
    }
    #menu-topic li:hover{
        font-weight:500;
    }
}

@media(max-width:768px){
    .add-question-box-filters{
        flex-wrap:wrap;
        margin-bottom:0;
        grid-gap:0;
        gap:0;
    }
    .add-question-box-title{
        flex:0 0 35%;
        margin-bottom:10px;
    }
    .add-question-select-box{
        flex:0 0 65%;
        margin-left:20px;
        margin-bottom:10px!important;
    }
    .add-question-box-title-input{
        flex:0 0 100%;
        margin-bottom:10px;
        height:38px;
    }
    .add-question-box-title-input input,
    .add-question-box-question-input textarea{
        font-size:12px;
        font-weight:normal;
    }
    .add-question-box-question-input textarea{
        line-height:1.83;
    }
    .add-question-select-box .MuiOutlinedInput-root{
        font-size:12px!important;
    }
    .add-question-box-footer .cancel-button, .add-question-box-footer .add-button{
        font-size:10px;
        height:28px;
        width:60px;
    }
    .add-question-box-footer #target-group-wrapper .target-group-selectbox > .MuiSelect-select.MuiSelect-filled.MuiSelect-filled{
        padding:5px 18px 5px 10px;
    }
    .add-question-box-footer #target-group-wrapper .target-group-selectbox .selectbox-wrapper .selectbox-icon{
        margin-right:8px;
    }
    .add-question-box-wrapper .target-group-selectbox{
        height:28px;
    }
    #menu-topic li{
        font-size:12px;
    }
    .add-question-box-wrapper{
        padding: 10px 8px 5px 8px;
    }
}

#contacts-widget {
  width: 290px;
}

#contacts-widget .contact-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
  }
  
  #contacts-widget .contact-item:not(:last-child) {
    margin-bottom: 12px;
  }
  
  #contacts-widget .contact-item .contact-avatar {
    width: 54px;
    height: 54px;
    padding: 3px;
    background-color: #f9f8fd;
    border-width: 2px;
    border-style: solid;
    margin-right: 14px;
}
  
  #contacts-widget .contact-item .contact-title{
    font-size: 18px;
    font-weight: 600;
    color: #3f526d;
}
#events-widget {
  width: 290px;
}

#events-widget .event-item-bottom{
  display:flex;
  justify-content:space-between;
  align-items:center;
}

#events-widget .card-divider{
  margin-bottom:0;
}

#events-widget .event-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 16px;
    margin-top:20px;
    position: relative;
  }

#events-widget .event-item-bottom button{
  font-size:12px!important;
  width:140px;
  height:32px;
  text-transform:capitalize!important;
  padding:0!important;
  color:#3f526d!important;
}

#events-widget .event-item-bottom .event-date{
  font-size:10px;
  color:#3f526d;
}

  #events-widget .event-item .event-avatar {
    margin-right: 19px;
    display: block;
  }

  #events-widget .event-item .event-title{
    font-size: 14px;
    font-weight: 600;
    display: block;
    margin-right: 30px;
    color: #3f526d;
  }

  #events-widget .event-item .event-close-button{
    position: absolute;
    right:0;
    top:0;
  }

  #events-widget .event-item .event-close-button svg.MuiSvgIcon-root{
    font-size: 16px;
    color: #e7e7e7;
  }

  #events-widget .empty-events-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 287px;
  }

  #events-widget .empty-events-wrapper img {

  }

  #events-widget .empty-events-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
  }

  @media(max-width:1024px){
    #events-widget .event-item {
      margin-bottom:8px;
    }
    #events-widget .event-item .event-title{
      font-weight:500;
    }
    #events-widget .event-item-wrapper:first-child .event-item{
      margin-top:8px;
    }
    #events-widget .event-item-wrapper:last-child .event-item-divider{
      display:none;
    }
    #events-widget .event-item-divider{
      margin-top:10px;
      margin-bottom:3px;
      border-bottom-width:0;
      border-color:rgba(63, 82, 109, 0.12);
    }
    #events-widget .event-item img{
      width:14px;
    }
  }

#notifications-widget {
  width: 290px;
}

#notifications-widget .notifications-clear-all-button{
    text-decoration: underline;
    text-transform: none;
    font-size: 12px;
  }
  
  #notifications-widget .notification-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 18px;
    position: relative;
  }
  
  #notifications-widget .notification-item .notification-avatar {
    width: 48px;
    height: 48px;
    padding: 3px;
    background-color: #f9f8fd;
    border-width: 2px;
    border-style: solid;
    margin-right: 15px;
  }
  
  #notifications-widget .notification-item .texts{
    margin-right: 30px;
  }
  
  #notifications-widget .notification-item .texts .notification-text{
    font-size: 14px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    color: #3f526d;
    letter-spacing: 0;
  }
  
  #notifications-widget .notification-item .texts .notification-created_at{
    font-size: 12px;
    opacity: 0.8;
    color: #3f526d;
  }
  
  #notifications-widget .notification-item .notification-close-button{
    position: absolute;
    right:0;
    top:0;
  }
  
  #notifications-widget .notification-item .notification-close-button svg.MuiSvgIcon-root{
    font-size: 16px;
    color: #e7e7e7;
  }
  
  #notifications-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top: 2px;
  }
  
  #notifications-widget .empty-notification-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
  }
  
  #notifications-widget .empty-notification-wrapper img {
      
  }
  
  #notifications-widget .empty-notification-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
  }
#other-students-goal-widget {
  width: 290px;
}

#other-students-goal-widget .widget-item {
    box-shadow: none;
    border: none;
    border-radius: 8px;
    padding: 14px;
  }
  
  #other-students-goal-widget .widget-item:not(:last-child) {
    margin-bottom: 20px;
  }
  
  #other-students-goal-widget .widget-item .widget-item-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
  
  #other-students-goal-widget .widget-item .widget-item-head .student-avatar {
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }
  
  #other-students-goal-widget .widget-item .widget-item-head .student-title {
    font-size: 12px;
    font-weight: 600;
    color: #3f526d;
  }
  
  #other-students-goal-widget .widget-item .student-goal {
    margin: 16px 0 20px 0;
    font-size: 12px;
    font-weight: 600;
    display: block;
    color: #3f526d;
  }
  
  #other-students-goal-widget .widget-item .due-date-wrapper{
    background-color: #fff;
    padding: 7px 15px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    color: #3f526d;
  }
  
  #other-students-goal-widget .widget-item .due-date-wrapper strong{
    margin-right: 4px;
    font-size: 12px;
    font-weight: 600;
  }
  
  #other-students-goal-widget .widget-item .due-date-wrapper span{
    font-size: 12px;
  }
#calendar-widget .sdp{
    width:unset;
    font-family:Open Sans,sans-serif;
    box-shadow:none;
    border:none;
    padding-right:0;
    padding-left:0;
}

#calendar-widget .sdp--header{
    border-bottom:1px solid rgba(63, 82, 109, 0.12);
    border-top:1px solid rgba(63, 82, 109, 0.12);
    padding:10px 0;
}

#calendar-widget.generic-card .card-divider{
    display:none;
}

#calendar-widget .sdp--header .sdp--square-btn{
    border:none;
    box-shadow:none;
    color:#3f526d;
    opacity:0.3;
}

#calendar-widget .sdp--header__main{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #3f526d;
}

#calendar-widget .sdp--grid>p.sdp--text{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7a879b;
}

#calendar-widget .sdp--grid{
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

#calendar-widget .sdp--grid:last-child .sdp--text {
    color: #3f526d;
    opacity:0.7;
}

#calendar-widget .sdp--grid:last-child .sdp--text__inactive {
    color: #3f526d;
    opacity:0.3;
}

#calendar-widget .sdp--grid:last-child .sdp--date-btn__selected{
    background-color:#7f6cfc;
    opacity:1;
    color:white;
}

#news-widget {
    width: 290px;
}

/*#news-widget .news-item{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    flex-wrap: nowrap;*/
/*    justify-content: flex-start;*/
/*    margin-bottom: 18px;*/
/*    position: relative;*/
/*}*/

#news-widget .news-item{
    margin-bottom:11px;
}

#news-widget .news-item .news-item-header{
    display:flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom:9px;
}

#news-widget .news-item .news-title{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

#news-widget .news-item .news-content{
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: #3f526d;
}

#news-widget .news-item .news-item-header .news-created_at{
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: rgba(63, 82, 109, 0.5);
}

#news-widget .news-item .news-divider{
    height: 1px;
    background: rgba(63, 82, 109, 0.12);
    width:80%;
    margin:10px 0;
}

#news-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top: 22px;
    height:44px;
}

#news-widget .empty-news-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
}

#news-widget .empty-news-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
}

#profile-widget{
  padding-left: 27px;
  padding-right: 24px;
}

#profile-widget .profile-widget-item:not(.profile-summary-view-wrapper){
  margin-top: 40px;
}

#profile-widget .about-me .section-title {
  margin-bottom: 8px;
  font-size: 16px;
  color: #3f526d;
  font-weight: 600;
}

#profile-widget .about-me .section-text {
  line-height: 22px;
  font-size: 12px;
  color: #3f526d;
  letter-spacing: 0;
}

/* @OTHER USERS */
#profile-widget .other-users .section-title {
  margin-bottom: 20px;
  font-size: 16px;
  color: #3f526d;
  font-weight: 600;
}

#profile-widget .other-users .users-wrapper {
  margin:0 -6px;
}

#profile-widget .other-users .user-item {
  width: 58px;
  flex: 0 0 58px;
  max-width: 58px;
  padding: 4px;
}

#profile-widget .other-users .user-item .user-avatar{
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

#profile-widget .other-users .view-all-button {
  text-transform: none;
  margin-top: 30px;
  font-size: 16px;
}
#other-members-widget{
    padding-left: 27px;
    padding-right: 24px;
}

/* @OTHER USERS */
#other-members-widget .other-users .section-title {
    margin-bottom: 20px;
    font-size: 16px;
    color: #3f526d;
    font-weight: 600;
}

#other-members-widget .other-users .users-wrapper {
    margin:0 -6px;
}

#other-members-widget .other-users .user-item {
    width: 58px;
    flex: 0 0 58px;
    max-width: 58px;
    padding: 4px;
}

#other-members-widget .other-users .user-item .user-avatar{
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

#other-members-widget .other-users .view-all-button {
    text-transform: none;
    margin-top: 30px;
    font-size: 16px;
}

#other-members-widget .empty-members-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    height: 246px;
}

#other-members-widget .empty-members-wrapper span {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 40px;
    text-align: center;
    font-size: 14px;
    color: rgba(63, 82, 109, 0.5);
    font-weight: 600;
}

#chat-widget {
    width: 290px;
}

#chat-widget .chat-item{
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    position: relative;
    padding:0!important;
    width:100%;
}

#chat-widget .chat-item:not(:last-child) {
    margin-bottom: 16px;
}

#chat-widget .chat-item .chat-avatar {
    width: 54px;
    height: 54px;
    padding: 3px;
    margin-right: 17px;
}

#chat-widget .chat-item .chat-title{
    text-transform: none!important;
    font-size: 18px!important;
    font-weight: 600!important;
    color: #3f526d;
}

#chat-widget .view-all-button {
    text-transform: none;
    font-size: 16px;
    margin-top:4px;
    margin-bottom:10px;
    height:44px;
}

.landing-page-main-container .tab-menu.box-view{
    display:flex;
    align-items:center;
    grid-gap:30px;
    gap:30px;
    margin-bottom: 7px;
}

.landing-page-main-container .tab-menu .MuiButtonBase-root {
    font-weight: normal;
    padding-left: 17px;
    padding-right: 17px;
}

.landing-page-main-container .tab-menu .MuiButtonBase-root[aria-selected="true"] {
    font-weight: 600;
    /* color: inherit ; */
}

.landing-page-main-container .tab-menu.box-view .TabMenu-root-5{
    padding:0 6px;
}

.filter-button{
    min-width:50px;
    height:50px;
    margin-bottom:24px;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
}

.filter-button img{
    width:24px;
    height:24px;
}

.filter-button button{
    min-width:unset;
    height:100%;
    width:100%;
    border-radius:8px;
    padding:0;
}

.filter-menu .MuiPopover-paper{
    border-radius: 8px!important;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    padding:16px 20px!important;
}

.filter-container{
    width:220px;
}

.filter-container .MuiMenu-list{
    padding:0;
}

.filter-menu-header{
    display:flex;
    justify-content: space-between;
    align-items:center;
    border-bottom:solid 0.5px #3f526d;
    margin-bottom:14px;
}

.filter-menu-header h3, .filter-menu-header span{
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;;
}

.filter-menu-header h3{
    font-size: 14px;
    font-weight: 600;
}

.filter-menu-header span{
    font-size:12px;
    text-decoration:underline;
    cursor:pointer;
}

.filter-select-box,
.filter-multiple-select-box{
    width:100%;
    margin-top:11px!important;
    margin-bottom:24px!important;
}

.filter-container label,
.filter-multiple-select-search{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #3f526d;
}

.filter-multiple-select-search input{
    font-size:12px;
}

.filter-select-box label{
    color:rgba(0, 0, 0, 0.3)!important;
}

.filter-multiple-select-box .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding:2px 9px;
}

.filter-menu-buttons{
    display:flex;
    align-items:center;
    grid-gap:10px;
    gap:10px;
}

.filter-container .filter-button{
    font-size: 14px;
    font-weight: 600!important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal!important;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    width:100px;
    height:36px;
    padding:9px 30px!important;
    border-radius: 6px;
}

.filter-container .filter-button:last-child{
    border-width: 0.5px;
    border-style: solid;
    color: #3f526d;
}

.select-box-chips{
    display:flex;
    grid-gap:2px;
    gap:2px;
}

.MuiChip-root{
    border-radius: 4px!important;
    background-color: rgb(127, 108, 252,0.1)!important;
    font-size: 14px!important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #253873!important;
    /*margin:0 5px 5px 0!important;*/
}

.filter-container .filter-select-box .MuiSelect-select{
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter-select-box .MuiInputLabel-outlined{
    transform: translate(14px, 12px) scale(1)!important;
}

.filter-select-box .MuiInputLabel-outlined.MuiFormLabel-filled{
    display:none;
}

.filter-multiple-select-search .MuiInputBase-input::-moz-placeholder{
    color: #3f526d!important;
}

.filter-multiple-select-search .MuiInputBase-input::placeholder{
    color: #3f526d!important;
}

.back-button{
    font-family: OpenSans,sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform:capitalize!important;
    margin-bottom:24px!important;
    padding:14px 20px!important;
    height:50px;
    border-radius:8px!important;
    box-shadow:none!important;
    margin-right:20px!important;
}

.back-button img{
    width:18px;
    height:18px;
}

.tab-menu.answer-view{
    grid-gap:0;
    gap:0;
}

.tab-menu.answer-view .filter-button{
    margin-left:10px!important;
}

.my-profile-button{
    padding: 14px 48px!important;
    border-radius: 8px!important;
    text-transform:capitalize!important;
    font-size:16px!important;
    font-weight:600!important;
}

.widget-menu .MuiPopover-paper {
    padding:20px;
    width:220px;
    border-radius:8px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
}

.widget-menu-buttons{
    display:flex;
    justify-content:space-between;
    margin-top:16px;
}

.widget-menu-form span{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
}

.widget-menu-buttons .widget-button{
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform:capitalize!important;
    box-shadow:none;
    width:84px;
    height:30px;
    border-radius:4px;
}

.widget-menu-buttons .widget-button:last-child{
    background-color: #f6f6f6;
    color:#3f526d;
}

@media(min-width:1024px){
    .mobile-profile-widget{
        display:none;
    }
}

@media(max-width:1024px){
    .landing-page-main-container .tab-menu.box-view{
        margin-bottom:0;
    }
    .mobile-profile-widget{
        background-color:#fff;
        border-radius:8px;
        box-shadow:0 0 15px 0 rgba(0, 0, 0, 0.1);
        padding:20px;
        margin-bottom:20px;
    }
    .mobile-profile-widget .profile-picture-area{
        margin:0!important;
    }
    .mobile-profile-widget .profile-summary-view-wrapper{
        display:flex;
        align-items:center;
    }
    .mobile-profile-widget .profile-title{
        margin-left:10px;
    }
    .mobile-profile-widget .user-summary-count-wrapper{
        margin-top:0!important;
        margin-left:6px;
        display:flex;
        justify-content:flex-end;
        grid-gap:20px;
        gap:20px;
        flex:1 1;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-picture-area{
        width:40px;
        height:40px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-title .fullname,
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count{
        font-size:14px;
        font-weight:500;
        text-align:left;
        margin-bottom:0!important;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count{
        margin-left:10px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .item-name{
        font-size:14px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-title .title-line .title{
        font-size:12px;
        white-space:nowrap;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item{
        box-shadow:none!important;
        height:unset;
        margin-bottom:0;
        padding:0;
    }
    .mobile-profile-widget .profile-summary-view-wrapper .profile-picture-area img{
        width:40px;
        height:40px;
    }
    .mobile-profile-widget .user-summary-count-wrapper img{
        width:24px;
    }
    #page-header .filter-button{
        width:30px;
        height:30px;
        box-shadow:none;
        min-width:unset;
        border-radius:6px;
        margin-bottom:0;
    }
    #page-header .filter-button svg{
        width:18px;
        height:18px;
    }
    .filter-menu-header h3,
    .filter-container label, .filter-multiple-select-search,
    .filter-container .filter-button{
        font-weight:500!important;
    }
    .filter-menu-header{
        padding-bottom:10px;
    }
    .filter-menu ul{
        padding:0;
    }
    .filter-select-box-list-item{
        font-size:14px!important;
        padding-left:10px!important;
    }
}

@media(max-width:768px){
    .mobile-profile-widget .user-summary-count-wrapper{
        flex:0 0 100%;
        margin-top:14px!important;
        justify-content:flex-start;
        margin-left:0;
        grid-gap:15px;
        gap:15px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper{
        flex-wrap:wrap;
    }
    .mobile-profile-widget .profile-summary-view-wrapper .profile-picture-area img,
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-picture-area{
        width:32px;
        height:32px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .profile-title .fullname{
        font-size:12px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count,
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .item-name{
        font-size:10px;
    }
    .mobile-profile-widget{
        padding:14px 10px;
        margin-bottom:10px;
    }
    .mobile-profile-widget .user-summary-count-wrapper img{
        width:16px;
    }
    .mobile-profile-widget .profile-summary-view-wrapper.__wide .user-summary-count-wrapper .summary-item .count{
        margin-left:6px;
    }
    .landing-page-main-container .tab-menu .MuiButtonBase-root{
        font-size:12px;
        padding:6px 4px;
    }
    .landing-page-main-container .tab-menu.box-view .WithWidth\(TabMenu\)-root-5{
        margin-bottom:10px;
        padding:0 6px;
    }
    .MuiDialog-paperWidthLg.MuiDialog-paperScrollBody{
        max-width: calc(100% - 32px)!important;
        border-radius:6px!important;
        width:100%;
    }
    .dialog-container .MuiDialog-paper{
        margin:16px;
    }
    .filter-menu-header h3,
    .filter-container label,
    .filter-container .filter-button{
        font-weight:normal!important;
    }
    .filter-menu-header h3{
        font-size:12px;
    }
    .filter-menu .MuiPopover-paper{
        padding:10px 10px 16px 10px!important;
        width:214px;
        top:117px!important;
        left:105px!important;
    }
    .filter-container{
        width:100%;
    }
    .filter-container .filter-button{
        width:80px;
        height:36px;
        font-size:12px;
    }
    .filter-menu-buttons{
        justify-content:center;
    }
    .filter-menu .MuiChip-label{
        font-size:12px;
    }
    #menu-sortedBy .MuiMenuItem-root{
        font-size:12px!important;
        min-height:unset!important;
    }
    .filter-select-box, .filter-multiple-select-box{
        margin-top:10px!important;
        margin-bottom:16px!important;
    }
}

@media(min-width:768px) and (max-width:1024px){
    .responsive-tab-menu{
        display:none!important;
    }
}

.shortcuts-container{
    display:flex;
    grid-gap:24px;
    gap:24px;
}

.shortcuts-left-column,
.shortcuts-right-column{
    display:flex;
    flex-direction:column;
    flex:0 0 50%;
    width: 50%;
    grid-gap:16px;
    gap:16px;
}

.shortcuts-left-column .generic-card,
.shortcuts-right-column .generic-card{
    width:100%!important;
    padding:20px 14px;
}

.shortcuts-container .generic-card .generic-card-subtitle,
.shortcuts-container #contacts-widget .contact-item .contact-title,
.shortcuts-container #chat-widget .chat-item{
    font-size:14px;
    font-weight:500;
}

.shortcuts-container .generic-card .card-divider{
    margin-top:10px;
    margin-bottom:8px;
}

.shortcuts-container #other-students-goal-widget .widget-item{
    padding:10px;
}

.shortcuts-container #other-students-goal-widget .widget-item .widget-item-head .student-avatar{
    width:28px;
    height:28px;
}

.shortcuts-container #other-students-goal-widget .widget-item .widget-item-head .student-title,
.shortcuts-container #other-students-goal-widget .widget-item .student-goal,
.shortcuts-container #news-widget .news-item .news-title{
    font-weight:500;
}

.shortcuts-container #other-students-goal-widget .widget-item .due-date-wrapper strong,
.shortcuts-container #other-students-goal-widget .widget-item .due-date-wrapper span{
    font-size:10px;
}

.shortcuts-container #other-students-goal-widget .widget-item:not(:last-child){
    margin-bottom:10px;
}

.shortcuts-container #other-students-goal-widget .widget-item .student-goal{
    margin:10px 0;
}

.shortcuts-container #other-students-goal-widget .widget-item .due-date-wrapper{
    padding:5px 0;
    width:162px;
    justify-content: center;
}

.shortcuts-container #news-widget .news-item .news-item-header{
    margin-bottom:4px;
}

.shortcuts-container #news-widget .news-item .news-divider{
    margin:12px 0 5px 0;
}

.shortcuts-container #news-widget .news-item{
    margin:0;
}

.shortcuts-container #news-widget .news-item .news-item-header .news-created_at{
    font-weight:normal;
}

.shortcuts-container #news-widget .view-all-button{
    height:38px;
    font-size:14px;
}

.shortcuts-container #other-members-widget .other-users .view-all-button{
    margin-top:18px;
    height:38px;
    font-size:14px;
}

.shortcuts-container #contacts-widget .contact-item .contact-avatar,
.shortcuts-container #chat-widget .chat-item .chat-avatar{
    width:44px;
    height:44px;
    margin-right:12px;
}

@media(max-width:768px){
    .shortcuts-container{
        flex-direction:column;
        grid-gap:10px;
        gap:10px;
    }
    .shortcuts-left-column, .shortcuts-right-column{
        flex: 0 0 100%;
        width: 100%;
        grid-gap:10px;
        gap:10px;
    }
    .shortcuts-left-column .generic-card, .shortcuts-right-column .generic-card{
        padding:10px 14px;
    }
    .shortcuts-container .generic-card .generic-card-subtitle,
    .shortcuts-container #chat-widget .chat-item,
    .shortcuts-container #other-members-widget .other-users .view-all-button{
        font-size:12px;
    }
    .shortcuts-container #events-widget .event-item .event-title,
    .shortcuts-container #chat-widget .chat-item .chat-title,
    .shortcuts-container #other-members-widget .other-users .section-title{
        font-size:12px!important;
        font-weight:500!important;
    }
    .shortcuts-container .generic-card .card-divider{
        margin-bottom:10px;
    }
    .shortcuts-container #events-widget .event-item,
    .shortcuts-container #notifications-widget .notification-item{
        margin-bottom:12px;
    }
    #contacts-widget .contact-item:not(:last-child){
        margin-bottom:17px;
    }
    .shortcuts-container #news-widget .news-item .news-title,
    .shortcuts-container #notifications-widget .notification-item .texts .notification-text{
        font-size:12px;
    }
    .shortcuts-container #news-widget .view-all-button{
        font-size:12px;
        margin-top:20px;
    }
    .shortcuts-container #news-widget .news-item .news-divider{
        margin:12px 0 3px 0;
    }
    .shortcuts-container #chat-widget .view-all-button{
        font-size:12px;
        height:38px;
    }
    .shortcuts-container #other-members-widget{
        padding-right:14px;
        padding-left:14px;
    }
    .shortcuts-container #other-members-widget .other-users .user-item{
        width: 48px;
        flex: 0 0 48px;
        max-width: 48px;
    }
}



.landing-page-main-container .widget-button {
    text-transform: unset;
    width: 120px;
    height: 44px;
}
.landing-page-main-container .pdf-button {
    width: 44px;
    height: 44px;
    min-width: unset;
    margin-left: 20px;
}

#landing-page-container {
    flex-flow: row nowrap;
}

#landing-page-container > .left-side,
#landing-page-container > .right-side {
    flex: 0 0 290px;
    max-width: 290px;
}

#page-header .right-side {
    display:flex;
}

#landing-page-container .content-side {
    flex: 1 1 auto;
    max-width: 100%;
    padding-left: 32px;
    padding-right: 32px;
    min-width: 0;
}

#landing-page-container .content-rightSide-fullWidth {
    padding-right: 0;
}

#landing-page-container .content-leftSide-fullWidth{
    padding-left: 0;
}

@media(max-width:1024px){
    #landing-page-container > .left-side,
    #landing-page-container > .right-side,
    .landing-page-main-container .widget-button {
        display:none;
    }

    #landing-page-container .content-side {
        padding:0!important;
    }

    .landing-page-main-container .pdf-button{
        width:30px;
        height:30px;
        border-radius:6px;
    }

    .landing-page-main-container .pdf-button img{
        width:18px;
        height:20px;
    }
}

@media(max-width:768px){
    .landing-page-main-container .pdf-button{
        margin-left:10px;
    }
}

