
#otp-form {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

#otp-form input {
  width: 58px;
  height: 58px;
  border-radius: 8px;
  border: solid 1px #cfcfcf;
  background-color: #f1f1f1;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #000;
}

#otp-form input:not(:last-child) {
  margin-right: 27px;
}